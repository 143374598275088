import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from 'react-redux'
import { insertServiceProposals, resetServiceProposalState } from '../../../actions/serviceProposals'

import * as webSocket from '../../../services/wss'
import MapView from "../../../components/MapView";
import ServiceProposalsBottomSheet from "../../../components/BottomSheets/ServiceProposals";
import api from "../../../services/api";
import { recreateSolicitation } from "../../../actions/solicitations";
import DepressionModal from "../../../components/Modal/Depression";
import { changeContext } from "../../../actions/context";
import LoadingVoctor from '../../../assets/images/vector/loading.svg'

import './styles.css'
import SolicitationBottomSheet from "../../../components/BottomSheets/Solicitation";
import FinishSolicitationBottomSheet from "../../../components/BottomSheets/FinishSolicitation";
import FinishServiceModal from "../../../components/Modal/FinishService";
import CancelSolicitationBottomSheet from "../../../components/BottomSheets/CancelSolicitation";
import RetrySolicitationBottomSheet from "../../../components/BottomSheets/RetrySolicitation";
import { setWebSocketConnection, setWebSocketReady } from "../../../actions/webScocket";
import { insertNewMessage } from "../../../actions/messages";

const sleep = time => new Promise(resolve => setTimeout(() => resolve(), time))

const Maps = () => {
    const dispatch = useDispatch()
    
    const user = useSelector(state => state.user)
    const lead = useSelector(state => state.lead)
    const context = useSelector(state => state.context)
    const modalControler = useSelector(state => state.modal)
    const solicitation = useSelector(state => state.solicitation)
    const webSocketReady = useSelector(state => state.webSocket.ready)
    const webSocketConnection = useSelector(state => state.webSocket.connection)
    const bottomSheetsControler = useSelector(state => state.bottomSheets)
    
    const [origin, setOrigin] = useState()
    const [routes, setRoutes] = useState()
    const [action, setAction] = useState()
    const [provider, setProvider] = useState()
    const [keepConnection, setTimer] = useState(0)
    const [destination, setDestination] = useState()
    const [showRoutes, setShowRoutes] = useState(true)
    const [loadInformation, setLoadInformation] = useState(true)

    const handleCleanupProviderInfo = async () => {
        await sleep(10000)
        setProvider()
        setDestination()
        setShowRoutes(true)
    }

    const startWebsocket = () => {
        const uuid = user.type === "lead" ? lead.uuid : user.uuid
        const connection = webSocket.connect(uuid, user.type)

        connection.onopen = () => { dispatch(setWebSocketReady(true)); };

        connection.onmessage = async (event) => {
            const data = JSON.parse(event.data)
            if (data.uuidSolicitation === solicitation.uuid) {
                if (data.action === "sendServiceProposals") {
                    dispatch(insertServiceProposals(data.serviceProposal))
                }
    
                if (data.action === "getSolicitation") {
                    setLoadInformation(false)
                    setOrigin({
                        lat: data.solicitation.current_location.latitude,
                        lng: data.solicitation.current_location.longitude,
                    })
                    dispatch(recreateSolicitation(data.solicitation))
                }
    
                if (data.action === "startSolicitation") {
                    dispatch(recreateSolicitation(data.solicitation))
                }
    
                if (data.action === "updateProviderLocation") {
                    setProvider(data)
    
                    setDestination({
                        lat: data.location.latitude,
                        lng: data.location.longitude,
                    })
                }
    
                if (["finishService", "startService", "canceledSolicitationByUser"].includes(data.action)) {
                    dispatch(recreateSolicitation(data.solicitation))
                }
    
                if (data.action === "lookingForProvider") {
                    setAction()
                    dispatch(recreateSolicitation(data.solicitation))
                }

                if (data.action === "sendMessage") {
                    dispatch(insertNewMessage(data))
                }
    
                if (data.action === "retrySolicitation") {
                    if (data.solicitation.status === "canceled") {
                        setAction(data.action)
                    } else {
                        setRoutes()
                        setProvider()
                        dispatch(resetServiceProposalState())
                        dispatch(recreateSolicitation(data.solicitation))
                    }
                }

                if (data.action === "sendLocationProvider") {
                    setShowRoutes(false)
                    setProvider(data.provider)
                    
                    setDestination({
                        lat: data.provider.location.latitude,
                        lng: data.provider.location.longitude,
                    })
                    
                    await handleCleanupProviderInfo()
                }
            }
        }

        dispatch(setWebSocketConnection(connection))
    }

    const getSolicitation = async () => {
        const message = {
            "action": "getSolicitation", 
            "solicitation": {
                "uuid": solicitation.uuid
            }
        }

        if (webSocketReady) {
            try {
                webSocketConnection.send(JSON.stringify(message))
            } catch (error) {
                await sleep(3000)
                getSolicitation()
            }
        }
    }

    const handleOnRetrySolicitation = async () => {
        await api.post("/solicitation/lookingForProvider/", {uuidSolicitation: solicitation.uuid})
        setTimeout(() => {getSolicitation()}, 3000)
    }

    useEffect(() => { if (!webSocketReady) startWebsocket() }, [webSocketReady])

    useEffect(() => {
        if (context.action === "getSolicitation") {
            getSolicitation()
        } else {
            setOrigin({
                lat: solicitation.current_location.latitude,
                lng: solicitation.current_location.longitude,
            })
            setLoadInformation(false)
        }
    }, [webSocketReady])

    useEffect(() => {
        (async () => {
            if (webSocketReady) {
                await sleep(1000)
                setTimer(keepConnection + 1)
                if (keepConnection === 60) {
                    setTimer(0)
                    webSocketConnection.send(JSON.stringify({ "action": "keepConnection" }))
                }            
            }
        })()
    }, [keepConnection, webSocketReady])

    if (loadInformation) {
        return (
            <div className="loading-solicitation-info-content">
                <img src={LoadingVoctor} alt="loading"/>
                <span>Carregando dados da sua solicitação...</span>
            </div>
        )
    } else {
        return (
            <>
                <DepressionModal 
                    activeReasonForCancelation
                    open={modalControler.depression}
                />
                <FinishServiceModal />
                <div style={{height: "100vh", width: "100%"}}>
                    <MapView 
                        origin={origin} 
                        showRoutes={showRoutes}
                        destination={destination}
                        onPlotRoute={(event) => setRoutes(event)}
                    />
                </div>
                {action === "retrySolicitation" ? (
                    <RetrySolicitationBottomSheet 
                        open={bottomSheetsControler.solicitation}
                        onRetrySolicitation={handleOnRetrySolicitation}
                    />
                ) : solicitation.status === "canceled" ? (
                    <CancelSolicitationBottomSheet
                        open={bottomSheetsControler.solicitation}
                    />
                ) : ["start_service", "finish_service"].includes(solicitation.stage) ? (
                    <FinishSolicitationBottomSheet
                        open={bottomSheetsControler.solicitation}
                    />
                ) : solicitation.status === "active" ? (
                    <SolicitationBottomSheet 
                        routes={routes}
                        provider={provider}
                        open={bottomSheetsControler.solicitation}
                    />
                ) : (
                    <ServiceProposalsBottomSheet 
                        open={bottomSheetsControler.solicitation}
                    />
                )}
            </>
        ) 
    }
}

export default Maps;