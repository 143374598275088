import { useRef } from 'react'

import { BottomSheet } from 'react-spring-bottom-sheet'

import PaperServiceProposal from '../../Paper/ServiceProposals'

import 'react-spring-bottom-sheet/dist/style.css'
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { refuseServiceProposalState, saveSelectedServiceProposals } from '../../../actions/serviceProposals'
import { refuseServiceProposal } from '../../../services/solicitations'
import { openBottomSheet } from '../../../actions/bottomSheets'
import { openModal } from '../../../actions/modal'
import Button from '../../Button'
import { changeContext } from '../../../actions/context'
import * as event from '../../../services/event'

const ServiceProposalsBottomSheet = ({
    open,
}) => {
    const sheetRef = useRef()

    const dispatch = useDispatch()

    const solicitation = useSelector(state => state.solicitation)
    const serviceProposals = useSelector(state => state.serviceProposals.list)
    const serviceProposalSelected = useSelector(state => state.serviceProposals.selected)

    const handleRefuseServiceProposal = async (serviceProposal) => {
        await refuseServiceProposal(serviceProposal)
        dispatch(refuseServiceProposalState(serviceProposal.uuid))
    }

    const handleOpenModal = () => {
        dispatch(openBottomSheet({bottomSheet: "solicitation", value: false}))
        dispatch(openModal({modal: "depression", value: true}))
    }

    const redirectToChat = () => {
        window.location.href = `https://wa.me/553131577411?text=Preciso de ajuda.`
    }

    const handleApproveServiceProposal = async (serviceProposal) => {
        dispatch(saveSelectedServiceProposals(serviceProposal))
        dispatch(changeContext({
            active: "auth",
            step: "",
            action: "approveServiceProposal"
        }))

        event.registerEvent({
            type: "approvedServiceProposal",
            uuidLead: solicitation.uuidLead,
            uuidSolicitation: solicitation.uuid,
            uuidServiceProposal: serviceProposal.uuid,
            price: serviceProposal.price
        })
    }

    return (
        <BottomSheet 
            className="bottom-sheet-container"
            ref={sheetRef}
            open={open}
            defaultSnap={({ maxHeight }) => maxHeight / 4}
            snapPoints={({ maxHeight, minHeight }) => [
                maxHeight - maxHeight / 10,
                maxHeight / 4,
                maxHeight * 0.6,
                minHeight
            ]}
        >
            <div className='bottom-sheet-content'>
                <span className='bottom-sheet-subtitle'>
                    {serviceProposals.filter(item => item.status !== "refused").length > 0 ? "Propostas" : "Estamos procurando por prestadores disponíveis em sua região"}
                </span>
                <div className='bottom-sheet-service-proposal-content'>
                    {serviceProposalSelected.status === "approved" ? (
                        [serviceProposalSelected].map((item, index) => {
                            return (
                                <PaperServiceProposal 
                                    key={index}
                                    serviceProposal={item}
                                    onSelected={() => dispatch(saveSelectedServiceProposals(item))}
                                    active={serviceProposalSelected.uuid === item.uuid}
                                    handleRefuseServiceProposal={() => handleRefuseServiceProposal(item)}
                                    handleApproveServiceProposal={() => handleApproveServiceProposal(item)}
                                />
                            )
                        })
                    ) : (
                        serviceProposals.length > 0 && serviceProposals.map((item, index) => {
                            if (item.status !== "refused") {
                                return (
                                    <PaperServiceProposal 
                                        key={index}
                                        serviceProposal={item}
                                        onSelected={() => dispatch(saveSelectedServiceProposals(item))}
                                        active={serviceProposalSelected.uuid === item.uuid}
                                        handleRefuseServiceProposal={() => handleRefuseServiceProposal(item)}
                                        handleApproveServiceProposal={() => handleApproveServiceProposal(item)}
                                    />
                                )
                            }
                        })
                    )}
                </div>
                <div className='bottom-sheet-button-content'>
                    <Button 
                        kind="secondary" 
                        label="Precisa de ajuda?"
                        onClick={redirectToChat}
                        style={{marginBottom: 8}}
                    />
                    <Button 
                        kind="error" 
                        label="Cancelar serviço"
                        onClick={handleOpenModal}
                    />
                </div>
            </div>
        </BottomSheet>
    )
}

export default ServiceProposalsBottomSheet;