import { createSlice } from '@reduxjs/toolkit'

export const servicesProposalsSlice = createSlice({
    name: 'servicesProposal',
    initialState: {
        list: [],
        selected: {
            uuid: undefined
        }
    },
    reducers: {
        insertServiceProposals: (state, action) => {
            if (Array.isArray(action.payload)) state.list = action.payload
            else state.list.push(action.payload)
        },
        saveSelectedServiceProposals: (state, action) => {
            state.selected = action.payload
        },
        changeSeriveProposal: (state, action) => {
            const { payload } = action
            state.selected[payload.column] = payload.value
        },
        resetServiceProposalState: (state) => {
            state.list = []
            state.selected = {
                uuid: undefined
            }
        },
        refuseServiceProposalState: (state, action) => {
            state.selected.status = "refused"

            state.list = state.list.map(item => {
                if (item.uuid === action.payload) {
                    item.status = "refused"
                }
                return item
            })
        }
    }
})

// Action creators are generated for each case reducer function
export const { 
    changeSeriveProposal,
    insertServiceProposals,
    resetServiceProposalState,
    saveSelectedServiceProposals,
    refuseServiceProposalState
} = servicesProposalsSlice.actions

export default servicesProposalsSlice.reducer