import CustomAvatar from "../../Avatar"

import './styles.css'

const PaperProvider = ({
    provider,
    style
}) => {
    return (
        <div style={style} className="paper-provider-container">
            <CustomAvatar 
                label={provider.name}
                size={32}
            />
            <div className="paper-provider-content">
                <label>{provider.name}</label>
                <span>{provider.company.name}</span>
            </div>
        </div>
    )
}

export default PaperProvider