import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { changeSolicitation, changeVehicle } from '../../../../actions/solicitations'
import { changeContext } from '../../../../actions/context'

import * as vehicleService from '../../../../services/vehicles'
import * as event from '../../../../services/event'

import InputText from '../../../../components/Input/Text'
import Button from '../../../../components/Button'

import VehicleVecotr from '../../../../assets/images/vector/vehicle.svg'

import FormsPneus from './Pneus'
import FormsReboque from './Reboque'
import FormsChaveiro from './Chaveiro'

import './styles.css'

const Step1 = () => {
    const dispatch = useDispatch()

    const leadState = useSelector(state => state.lead)
    const solicitation = useSelector(state => state.solicitation)

    const [notInformPlate, setNotInformePlate] = useState(false)
    const [loadingVehicleInformation, setLoadingVehicleInformation] = useState(false)
    const [formsError, setErro] = useState({
        name: {error: false, message: "Nome deve conter pelo menos 4 caracteres."},
        vehicle: {error: false, message: "Veículo inválido"},
        cellphone: {error: false, message: "Telefone inválido"},
        type: {error: false, message: "A resposta não pode ser vazia."},
    })

    const [chaveiroFormsError, setChaveiroFormsError] = useState({
        keysConditions: {error: false, message: "A resposta não pode ser vazia."},
        keysHasExtrakey: {error: false, message: "A resposta não pode ser vazia."}
    })

    const [pneuFormsError, setPneuFormsError] = useState({
        tireHasTools: {error: false, message: "A resposta não pode ser vazia."},
        tireConditions: {error: false, message: "A resposta não pode ser vazia."},
        tireHasSteppe: {error: false, message: "A resposta não pode ser vazia."},
        tireSteppeIsFull: {error: false, message: "A resposta não pode ser vazia."}
    })

    const [reboqueFormsError, setReboqueFormsError] = useState({
        wheelsConditions: {error: false, message: "A resposta não pode ser vazia."},
        destinyLocation: {error: false, message: "Precisamos saber para qual endereço vamos levar o veículo."},
    })

    const vehicleType = [
        {label: "Moto", id: "Moto"},
        {label: "Carro", id: "Auto"}
    ]

    const regex = {
        name: /^[a-zA-Z\u00C0-\u017F´]{4,}(?: [a-zA-Z\u00C0-\u017F´]+){0,}$/,
        vehicle: /^[A-Z]{3}[0-9][A-Z0-9][0-9]{2}$/i,
        contact: /^(\+55)?\s?\(?\d{2}\)?\s?9\d{4}-?\d{4}$/g
    }

    const formatVehicle = (vehicle) => {
        return `${vehicle.brand} - ${vehicle.model} ${vehicle.year}/${vehicle.yearModel} - ${vehicle.color}`
    }

    const handleRegisterEventForms = (eventName, value) => {
        event.registerEvent({
            type: eventName,
            uuidLead: leadState.uuid,
            eventData: value
        })
    }

    const handleNotInformPlate = () => {
        setNotInformePlate(!notInformPlate)
    }

    const handleConsultVehicle = async () => {
        if (!notInformPlate) {
            setLoadingVehicleInformation(true)
            setErro({ ...formsError, placa: { ...formsError.placa, error: false}})
    
            if (regex.vehicle.test(solicitation.placa)) {
                const response = await vehicleService.consultVehicle(solicitation.placa)
                if (response) {
                    dispatch(changeVehicle({
                        service: solicitation.service,
                        vehicle: formatVehicle(response),
                        vehicleCategory: response.category,
                        vehicleOrigin: response.origin,
                        vehicleType: response.type
                    }))
                } else {
                    setErro({ ...formsError, placa: { ...formsError.placa, error: true}})
    
                    dispatch(changeVehicle({
                        service: solicitation.service,
                        vehicle: "",
                        vehicleCategory: "",
                        vehicleOrigin: "",
                        vehicleType: ""
                    }))

                    setNotInformePlate(true)
                }
            } else {
                setErro({ ...formsError, placa: { ...formsError.placa, error: true}})
            }
    
            setLoadingVehicleInformation(false)
        }
    }

    const disableButton = () => {
        const checkVehicle = solicitation.vehicle === ""

        const checkTiresConfig = solicitation.service === "pneu" && (
            solicitation.tiresConfig.tire_conditions === "" || 
            solicitation.tiresConfig.has_steppe === "" ||
            solicitation.tiresConfig.has_tools === "" ||
            (solicitation.tiresConfig.has_steppe === "sim" && solicitation.tiresConfig.steppe_is_full === "")
        )

        const checkWheelsConfig = solicitation.service === "reboque" && (
            solicitation.wheelsConfig.condition === ""
        )

        const checkKeysConfig = solicitation.service === "chaveiro" && (
            solicitation.keyConfig.condition === "" ||
            (solicitation.keyConfig.condition === "quebrada" && solicitation.keyConfig.hasExtrakey === "")
        )

        if (
            checkVehicle ||
            checkKeysConfig ||
            checkTiresConfig ||
            checkWheelsConfig
        ) {
            return true
        }
        return false
    }

    const showError = () => {
        const checkVehicle = solicitation.vehicle === ""

        const checkTiresConfig = solicitation.service === "pneu" && (
            solicitation.tiresConfig.tire_conditions === "" || 
            solicitation.tiresConfig.has_steppe === "" ||
            solicitation.tiresConfig.has_tools === "" ||
            (solicitation.tiresConfig.has_steppe === "sim" && solicitation.tiresConfig.steppe_is_full === "")
        )

        const checkWheelsConfig = solicitation.service === "reboque" && (
            solicitation.wheelsConfig.condition === ""
        )

        const checkKeysConfig = solicitation.service === "chaveiro" && (
            solicitation.keyConfig.condition === "" ||
            (solicitation.keyConfig.condition === "quebrada" && solicitation.keyConfig.hasExtrakey === "")
        )

        if (
            checkVehicle ||
            checkKeysConfig ||
            checkTiresConfig ||
            checkWheelsConfig
        ) {
            if (checkVehicle) {
                setErro({
                    ...formsError,
                    vehicle: {message: notInformPlate ? "Veículo inválido." : "Placa inválida.", error: true}
                })
            }

            if (checkKeysConfig) {
                if (solicitation.keyConfig.condition === "") setChaveiroFormsError({ ...chaveiroFormsError, keysConditions: {...chaveiroFormsError.keysConditions, error: true}})
                else if (solicitation.keyConfig.condition === "quebrada" && solicitation.keyConfig.hasExtrakey === "") setChaveiroFormsError({ ...chaveiroFormsError, keysHasExtrakey: {...chaveiroFormsError.keysHasExtrakey, error: true}})
            }

            if (checkTiresConfig) {
                if (solicitation.tiresConfig.tire_conditions === "") {
                    setPneuFormsError({
                        ...pneuFormsError,
                        tireConditions: {...pneuFormsError.tireConditions, error: true}
                    })
                } else if (solicitation.tiresConfig.has_tools === "") {
                    setPneuFormsError({
                        ...pneuFormsError,
                        tireHasTools: {...pneuFormsError.tireHasTools, error: true}
                    })
                } else if (solicitation.tiresConfig.has_steppe === "") {
                    setPneuFormsError({
                        ...pneuFormsError,
                        tireHasSteppe: {...pneuFormsError.tireHasSteppe, error: true}
                    })
                } else if (solicitation.tiresConfig.has_steppe === "sim" && solicitation.tiresConfig.steppe_is_full === "") {
                    setPneuFormsError({
                        ...pneuFormsError,
                        tireSteppeIsFull: {...pneuFormsError.tireSteppeIsFull, error: true}
                    })
                }
            }

            if (checkWheelsConfig) {
                setReboqueFormsError({
                    destinyLocation: {...reboqueFormsError.destinyLocation, error: false},
                    wheelsConditions: {...reboqueFormsError.wheelsConditions, error: true}
                })
            }
        }
    }

    const handleNextStep = () => {
        if (!disableButton()) {
            handleRegisterEventForms("informedVehicle", solicitation.vehicle)
            dispatch(changeContext({
                active: "solicitation",
                step: "forms",
                action: "step2"
            }))
        } else {
            showError()
        }
    }

    const redirectToChat = () => {
        window.location.href = `https://wa.me/553131577411?text=Preciso de ajuda.`
    }

    useEffect(() => {
        if (regex.vehicle.test(solicitation.placa)) {
            handleConsultVehicle()
        }
    }, [solicitation.placa])

    return (
        <div className='vehicle-forms-container'>
            <div className='vehicle-forms-vector-content'>
                <img alt="" src={VehicleVecotr} />
            </div>
            <div className='vehicle-forms-header'>
                <div className='vehicle-forms-describe'>
                    <p>
                        Consultamos a placa do seu veículo para obter rapidamente a marca, o modelo, o ano e a cor, 
                        facilitando assim a identificação pelo prestador ao chegar no local do veículo.
                    </p>
                    <button onClick={handleNotInformPlate}>
                        {notInformPlate ? "Informar apenas a placa" : "Informar modelo, ano e cor do veículo manualmente"}
                    </button>
                </div>
            </div>
            <div className='vehicle-forms-content'>
                {notInformPlate &&
                    <div className='forms-vehicle-type'>
                        {vehicleType.map((item, index) => {
                            return (
                                <button
                                    key={item.id}
                                    className={`forms-vehicle-type-buttom ${item.id === solicitation.vehicleType && "active"}`}
                                    onClick={() => dispatch(changeVehicle({
                                        service: solicitation.service,
                                        vehicle: solicitation.vehicle,
                                        vehicleCategory: solicitation.vehicleCategory,
                                        vehicleOrigin: solicitation.vehicleOrigin,
                                        vehicleType: item.id
                                    }))}
                                >
                                    {item.label}
                                </button>
                            )
                        })}
                    </div>
                }
                <InputText 
                    maxLength={!notInformPlate && 7}
                    value={notInformPlate ? solicitation.vehicle : solicitation.placa}
                    placeholder={!notInformPlate && "AAA0X00 ou AAA9999"}
                    label={notInformPlate ? "Digite o modelo, ano e a cor do veículo" : "Digite a placa do seu veículo"}
                    error={formsError.vehicle.error}
                    helperText={formsError.vehicle.message}
                    onChange={(event) => dispatch(changeSolicitation({
                        column: notInformPlate ? "vehicle" : "placa", 
                        value: String(event.target.value).toLocaleUpperCase()
                    }))}
                />
                {loadingVehicleInformation &&
                    <div className='forms-vehicle-result-content'>
                        <span className='forms-formatted-vehicle'>Carregando dados do veículo...</span>
                    </div>
                }
                {loadingVehicleInformation || solicitation.vehicle !== "" && !notInformPlate &&
                    <div className='forms-vehicle-result-content'>
                        <span className='forms-formatted-vehicle'>
                            {loadingVehicleInformation ? (
                                "Carregando dados do veículo..."
                            ) : (
                                solicitation.vehicle
                            )}
                        </span>
                    </div>
                }
                {solicitation.service === "reboque" &&
                    <FormsReboque 
                        errorConditions={reboqueFormsError.wheelsConditions.error}
                    />
                }
                {solicitation.service === "pneu" && 
                    <FormsPneus 
                        errorTireCondition={pneuFormsError.tireConditions.error}
                        errorHasTools={pneuFormsError.tireHasTools.error}
                        errorHasSteppe={pneuFormsError.tireHasSteppe.error}
                        errorSteppeIsFull={pneuFormsError.tireSteppeIsFull.error}
                    />
                }
                {solicitation.service === "chaveiro" && 
                    <FormsChaveiro 
                        errorConditions={chaveiroFormsError.keysConditions.error} 
                        errorHasExtraKeys={chaveiroFormsError.keysHasExtrakey.error}
                    />
                }
            </div>
            <div className="vehicle-forms-footer">
                {false &&
                    <button 
                        className='vehicle-forms-goback'
                        onClick={() => dispatch(changeContext({
                            active: "solicitation",
                            step: "forms",
                            action: "step1"
                        }))}
                    >
                        Voltar
                    </button>
                }
                <Button 
                    disabled={disableButton()}
                    onClick={handleNextStep}
                    loading={loadingVehicleInformation}
                    label="Próximo: Localização do veículo"
                    //style={{width: "80%"}}
                />
            </div>
            <div className='lead-forms-footer-hr'/>
            <Button 
                kind="secondary"
                onClick={redirectToChat}
                label="Solicitar pelo WhatsApp"
                style={{marginTop: 8}}
            />
        </div>
    )
}
export default Step1