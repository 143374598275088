import { useEffect, useState } from "react";
import { loadServices, loadingServices } from "../../actions/services";
import { useDispatch, useSelector } from "react-redux";

import Layout from "../../components/Layout";

import Services from "./Services";
import Forms from "./Forms";
import Maps from "./Maps";
import api from "../../services/api";
import * as event from '../../services/event'
import { loadLead, setUuidLead } from "../../actions/lead";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const Solicitation = () => {
    const dispatch = useDispatch()
    const [lead, setLead, getLead] = useLocalStorage("lead", null);

    const contextControler = useSelector(state => state.context)

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const uuidSolicitation = urlParams.get('solicitacao');

    const getServicesActive = async () => {
        dispatch(loadingServices(true))
        try {
            const response = await api.get('service/active')
            if (response.status === 200) {
                dispatch(loadServices(response.data))
            }
        } catch (error) {
            console.log("getServicesActive", error)
        }
        dispatch(loadingServices(false))
    }

    const steps = {
        services: <Services />,
        forms: <Forms />
    }

    useEffect(() => {
        getServicesActive()
    }, [])

    useEffect(() => {
        (async() => {
            const uuidLead = getLead("lead")
            const response = await event.registerEvent({
                type: "openSystem",
                url: window.location.href,
                uuidLead: uuidLead,
                origin: "web",
                uuidSolicitation: uuidSolicitation
            })

            setLead(response.uuidLead)
            dispatch(loadLead(response))
        })()
    }, [])

    if (contextControler.step === "maps") return <Maps />
    else return <Layout> {steps[contextControler.step]} </Layout> 
}
export default Solicitation;