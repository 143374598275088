import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
    name: "modal",
    initialState: {
        depression: false,
        finishService: false,
        confirmLocation: false,
        impossibleService: false,
        requestGeolocation: false,
    },
    reducers: {
        openModal: (state, action) => {
            const { payload } = action
            state[payload.modal] =  payload.value
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    openModal
} = modalSlice.actions

export default modalSlice.reducer