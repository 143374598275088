import { useDispatch, useSelector } from "react-redux"
import InputSelect from "../../../../../components/Input/Select"
import { changeKeyConfig, changeTiresConfig, changeWheelsConditions } from "../../../../../actions/solicitations"

const FormsRoboque = ({
    errorConditions
}) => {
    const solicitation = useSelector(state => state.solicitation)
    const dispatch = useDispatch()

    const wheelsConditionsOptions = [
        {id: "lockedWheels", label: "As rodas estão travadas"},
        {id: "freeWheels", label: "As rodas estão livres"}
    ]

    return (
        <>
            {solicitation.vehicleType !== "Moto" &&
                <div className='vehicle-forms-input-item'>
                    <div className='vehicle-forms-title-input' style={{marginBottom: 8}}>
                        <span>Como está a situação das rodas?</span>
                    </div>
                    <InputSelect 
                        label="Qual a situação da rodas?"
                        options={wheelsConditionsOptions}
                        style={{marginBottom: 6}}
                        value={solicitation.wheelsConfig.condition}
                        error={errorConditions}
                        helperText="A resposta não pode ser vazia."
                        onChange={(event) => dispatch(changeWheelsConditions({
                            column: "condition", 
                            value: event.target.value
                        }))}
                    />
                </div>
            }
        </>
    )
}

export default FormsRoboque