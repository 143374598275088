import { useState } from "react"

import api from "../../../../services/api"

import Button from "../../../../components/Button"
import InputText from "../../../../components/Input/Text"
import CustomAlert from "../../../../components/Alert"
import { useDispatch } from "react-redux"
import { changeContext, changeStep } from "../../../../actions/context"

const PasswordStep1 = ({
    form,
    changeForm
}) => {
    const regex = {
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[,.;:/?~^´`'"!@#$%^&*])(?=.{8,})/,
        email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    }

    const dispatch = useDispatch()

    const [loadingButton, setLoadingButton] = useState(false)
    const [error, setError] = useState({
        email: {error: false, message: "Usuário não encontrado."}
    })

    const checkEmail = () => {
        return regex.email.test(form.email)
    }

    const validateEmail = async () => {
        setLoadingButton(true)

        const params = {
            params: {
                email: form.email
            }
        }

        try {
            const response = await api.get(`/resetPassword/validateEmail`, params)
            if(response.status === 200) {
                changeForm("uuid", response.data.uuidUser)
                dispatch(changeStep("passwordStep2"))
            } else {
                setError({...error, email: {...error.email, error: true}})
            }
        } catch (e) {
            setError({...error, email: {...error.email, error: true}})
        }

        setLoadingButton(false)
    }

    return (
        <>
            <div className='auth-content'>
                <span className='auth-subtitle'>Bem vintdo à Keeper</span>
                <h2>Esqueceu a senha?</h2>
                <div className='auth-forms-content' style={{gridTemplateRows: "1fr 1fr"}}>
                    <InputText 
                        type="email"
                        label="Seu e-mail"
                        value={form.email}
                        helperText="Erro: E-mail inválido."
                        error={!checkEmail() && form.email !== ''}
                        onChange={(event) => changeForm("email", event.target.value)}
                        style={{marginBottom: 12}}
                    />
                    <Button 
                        disabled={!checkEmail()}
                        loading={loadingButton}
                        onClick={validateEmail}
                        label="Solicitar token"
                    />
                </div>
                <div className='auth-link-content'>
                    <span>Já possui uma conta?</span>
                    <button 
                        className='auth-link' 
                        style={{marginTop: 0, marginBottom: 0}}

                        onClick={() => dispatch(changeContext({
                            step: "",
                            action: "",
                            active: "auth",
                        }))}
                    >
                        Faça o login!
                    </button>
                </div>
            </div>
            <CustomAlert
                show={error.email.error}
                message={error.email.message}
                onClose={() => setError({...error, email: {...error.email, error: false}})}
            />
        </>
    )
}

export default PasswordStep1