import { useDispatch, useSelector } from 'react-redux';
import { changeContext } from '../../../actions/context';
import PaymentSuccessIcon from '../../../assets/images/icon/success.svg'
import { setWebSocketReady } from '../../../actions/webScocket';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { setUuidLead } from '../../../actions/lead';

import './styles.css'
import { useEffect } from 'react';
import * as event from '../../../services/event'

const PaymentSuccess = () => {
    const dispatch = useDispatch()
    const [lead, setLead, getLead] = useLocalStorage("lead", null);

    const solicitation = useSelector(state => state.solicitation)
    const webSocketConnection = useSelector(state => state.webSocket.connection)
    
    if (webSocketConnection.readyState === 1) webSocketConnection.close()

    const gtagReportConversion = (value) => {
        window.gtag('event', 'conversion', {
            'send_to': 'AW-11179585816/dIO4CLWw-5QZEJji69Ip',
            'value': value,
            'currency': 'BRL',
            'transaction_id': '',
        })
    }
    
    setTimeout(() => {
        gtagReportConversion(solicitation.price * 0.25)

        dispatch(setWebSocketReady(false))

        dispatch(changeContext({
            step: "maps",
            active: "solicitation",
            action: "",
        }))

    }, 3000);
    
    useEffect(() => {
        event.registerEvent({
            type: "hiredService",
            uuidLead: solicitation.uuidLead,
            uuidUser: solicitation.uuidUser,
            uuidSolicitation: solicitation.uuid,
            price: solicitation.price
        })
    }, [])

    return (
        <div className="payment-success-container">
            <div className="payment-success-content">
                <img src={PaymentSuccessIcon} alt="success" />
            </div>
            <div class="payment-success-result">
                <h1 >Sucesso!!</h1>
                <span> Contratação realizada com sucesso. </span>
            </div>
        </div>
    )
}

export default PaymentSuccess;