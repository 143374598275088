import { createSlice } from '@reduxjs/toolkit'

export const paymentsSlice = createSlice({
    name: 'payments',
    initialState: {
        status: ""
    },
    reducers: {
        changePaymentStatus: (state, action) => {
            state.status = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { 
    changePaymentStatus
} = paymentsSlice.actions

export default paymentsSlice.reducer