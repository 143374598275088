import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { useEffect } from 'react';

const CustomAlert = ({
    show,
    message,
    onClose
}) => {

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                onClose()
            }, 5000)
        }
    }, [show])

    return (
        <Collapse in={show} style={{position: 'absolute', width: "95%"}}>
            <Alert severity="error">{message}</Alert>
        </Collapse>
    )
}

export default CustomAlert;