import api from "./api"

export const createPayments = async (serviceProposal) => {
    try {
        const response = await api.post('/payments', serviceProposal)
        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log(error)
    }
}

export const getCards = async (uuidUser) => {
    try {
        const response = await api.get('/payments/getCards', {params: {user: uuidUser}})
        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log(error)
    }
}