import  {
    APIProvider,
    Map,
    Polyline 
} from '@vis.gl/react-google-maps'
import { Directions } from './directions'
import { Marker } from './marker'

const MapView = ({
    origin,
    destination,
    onPlotRoute,
    showRoutes
}) => {

    return (
        <APIProvider
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        >
            <Map 
                defaultCenter={origin} 
                defaultZoom={15}
                mapId="solicitation"
                gestureHandling={'greedy'}
                disableDefaultUI={true}
            >
                {origin && <Marker position={origin} icon="carro"/>}
                {destination && <Marker position={destination} icon="mecanico"/>}
                {showRoutes && <Directions origin={origin} destination={destination} onPlotRoute={onPlotRoute}/>}
            </Map>
        </APIProvider>
    )
}
export default MapView