import { useRef } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { useDispatch, useSelector } from 'react-redux'

import SmallCashIcon from '../../../assets/images/icon/s-cash.svg';
import SmallPointIcon from '../../../assets/images/icon/small-point.svg';

import 'react-spring-bottom-sheet/dist/style.css'
import './styles.css'
import Button from '../../Button'
import { openBottomSheet } from '../../../actions/bottomSheets'
import { openModal } from '../../../actions/modal'
import PaperProvider from '../../Paper/Provider'

const SolicitationBottomSheet = ({
    open,
    routes,
    provider
}) => {
    const dispatch = useDispatch()
    const sheetRef = useRef()

    const solicitation = useSelector(state => state.solicitation)

    const handleOpenModal = () => {
        dispatch(openBottomSheet({bottomSheet: "solicitation", value: false}))
        dispatch(openModal({modal: "depression", value: true}))
    }

    const redirectToChat = () => {
        window.location.href = `https://wa.me/553131577411?text=Preciso de ajuda.`
    }

    return (
        <BottomSheet 
            className="bottom-sheet-container"
            ref={sheetRef}
            open={open}
            defaultSnap={({ maxHeight }) => maxHeight * 0.6}
            snapPoints={({ maxHeight, minHeight }) => [
                maxHeight - maxHeight / 10,
                maxHeight / 4,
                maxHeight * 0.6,
                minHeight
            ]}
        >
            <div className='bottom-sheet-content'>
                <div className='bottom-sheet-title-content'>
                    {provider && routes ? (
                        <div className='bottom-sheet-provider-content'>
                            <PaperProvider provider={provider} />
                            <div>
                                <div className='bottom-sheet-duration-title'>
                                    <img src={SmallPointIcon} alt="location"/>
                                    <span>Deslocamento</span>
                                </div>
                                <span className='bottom-sheet-duration'>Em aproximadamente <span className='bottom-sheet-duration bold'>{routes.duration.text}</span> o prestador chegará até você.</span>
                            </div>
                        </div>
                    ) : (
                        <span className='bottom-sheet-subtitle'>Aguardando dados da localização do prestador</span>
                    )}
                </div>
                <div className='bottom-sheet-content-divider'/>
                <div className='bottom-sheet-validate-code-content'>
                    <label>{solicitation.validateCode}</label>
                    <span>Informe o código de validação acima quando o prestador chegar.</span>
                </div>
                <div className='bottom-sheet-content-divider'/>
                {solicitation.type !== "purchase" &&
                    <div className='bottom-sheet-price-content'>
                        <div className='bottom-sheet-price-title'>
                            <img src={SmallCashIcon} alt="cash"/>
                            <span>Valor total à pagar pelo serviço</span>
                        </div>
                        <span>A cobrança somente será executado após a finalização do serviço</span>
                        <label>{Number(solicitation.price).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</label>
                    </div>
                }
                <div className='bottom-sheet-button-content'>
                    <Button 
                        kind="secondary" 
                        label="Precisa de ajuda?"
                        onClick={redirectToChat}
                        style={{marginBottom: 8}}
                    />
                    <Button 
                        kind="error" 
                        label="Cancelar serviço"
                        onClick={handleOpenModal}
                    />
                </div>
            </div>
        </BottomSheet>
    )
}

export default SolicitationBottomSheet;