import { useDispatch, useSelector } from "react-redux"
import InputSelect from "../../../../../components/Input/Select"
import { changeKeyConfig, changeTiresConfig } from "../../../../../actions/solicitations"

const FormsChaveiro = ({
    errorConditions,
    errorHasExtraKeys
}) => {
    const solicitation = useSelector(state => state.solicitation)
    const dispatch = useDispatch()

    const keyConditionsOptions = [
        {id: "quebrada", label: "Quebrada"},
        {id: "inVehicle", label: "Dentro do veículo"}
    ]

    const hasExtraKeyOptions = [
        {id: "sim", label: "Sim"},
        {id: "não", label: "Não"},
    ]

    return (
        <>
            {solicitation.vehicleType !== "Moto" &&
                <div className='vehicle-forms-input-item'>
                    <div className='vehicle-forms-title-input' style={{marginBottom: 8}}>
                        <span>Como está a situação das chaves?</span>
                    </div>
                    <InputSelect 
                        label="Qual a situação da chave?"
                        options={keyConditionsOptions}
                        style={{marginBottom: 6}}
                        value={solicitation.keyConfig.condition}
                        error={errorConditions}
                        helperText="A resposta não pode ser vazia."
                        onChange={(event) => dispatch(changeKeyConfig({
                            column: "condition", 
                            value: event.target.value
                        }))}
                    />
                    {solicitation.keyConfig.condition === "quebrada" &&
                        <InputSelect 
                            label="Você possui chave reserva?"
                            options={hasExtraKeyOptions}
                            style={{marginBottom: 6, marginTop: 6}}
                            value={solicitation.keyConfig.hasExtrakey}
                            error={errorHasExtraKeys}
                            helperText="A resposta não pode ser vazia."
                            onChange={(event) => dispatch(changeKeyConfig({
                                column: "hasExtrakey", 
                                value: event.target.value
                            }))}
                        />
                    }
                </div>
            }
        </>
    )
}

export default FormsChaveiro