import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import { setLeadName, setUuidLead } from '../../../../actions/lead'
import { changeContext } from '../../../../actions/context'
import { changeSolicitation, recreateSolicitation } from '../../../../actions/solicitations'

import * as event from '../../../../services/event'
import * as solicitationService from '../../../../services/solicitations'

import Button from '../../../../components/Button'
import InputText from '../../../../components/Input/Text'
import ContactVector from '../../../../assets/images/vector/contact.svg'

import './styles.css'
import { openModal } from '../../../../actions/modal'

const Step3 = () => {
    const dispatch = useDispatch()
    const [__, setLead, _] = useLocalStorage("lead", null)
    
    const user = useSelector(state => state.user)
    const leadState = useSelector(state => state.lead)
    const solicitation = useSelector(state => state.solicitation)
    const servicesState = useSelector(state => state.services.auto)

    const [loadingSubmit, setLoadignSubmit] = useState(false)
    const [formsError, setErro] = useState({
        name: {error: false, message: "Nome deve conter pelo menos 4 caracteres."},
        placa: {error: false, message: "Placa inválida"},
        cellphone: {error: false, message: "Telefone inválido"},
        type: {error: false, message: "A resposta não pode ser vazia."},
    })

    const regex = {
        name: /^[a-zA-Z\u00C0-\u017F´]{4,}(?: [a-zA-Z\u00C0-\u017F´]+){0,}$/,
        placa: /^[A-Z]{3}[0-9][A-Z0-9][0-9]{2}$/i,
        contact: /^(\+55)?\s?\(?\d{2}\)?\s?9\d{4}-?\d{4}$/g
    }

    const services = {
        "mecanico": 'Mecânico',
        "bateria": 'Socorro Bateria',
        "chaveiro": 'Chaveiro',
        "combustivel": 'Combustível',
        "pneu": 'Troca de Pneus',
        "reboque": "Reboque"
    }

    const checkName = () => {
        const text = leadState.name.replace(/^\s+|\s+$/gm,'');

        if (text !== '' && regex.name.test(text)) {
            setErro({ ...formsError, name: { ...formsError.name, error: false}})
            return true
        }
        setErro({ ...formsError, name: { ...formsError.name, error: true}})
        return false
    }

    const checkCellphone = () => {
        if (solicitation.cellphone !== '' && regex.contact.test(solicitation.cellphone)) {
            setErro({ ...formsError, cellphone: { ...formsError.cellphone, error: false}})
            handleRegisterEventForms("informedContact", {
                name: leadState.name,
                cellphone: solicitation.cellphone,
                service: solicitation.service
            })
            return true
        }
        setErro({ ...formsError, cellphone: { ...formsError.cellphone, error: true}})
        return false
    }

    const handleRegisterEventForms = (eventName, value) => {
        event.registerEvent({
            type: eventName,
            uuidLead: leadState.uuid,
            eventData: value
        })
    }

    const disableButton = () => {
        const name = leadState.name.replace(/^\s+|\s+$/gm,'')

        const checkName = user.type === "lead" && leadState.name === "" && (name === '' || !regex.name.test(name))
        const checkCellphone = solicitation.cellphone === "" || solicitation.cellphone.length < 15

        if (checkName || checkCellphone) {
            return true
        }
        return false
    }

    const showError = () => {
        const name = leadState.name.replace(/^\s+|\s+$/gm,'')

        const checkName = user.type === "lead" && leadState.name === "" && (name === '' || !regex.name.test(name))
        const checkCellphone = solicitation.cellphone === "" || solicitation.cellphone.length < 15

        if (checkName || checkCellphone) {
            const keyValues = ["name", "cellphone"]

            const keyValuesError = keyValues.filter(item => {
                if (item === "name" && checkName) return item
                if (item === "cellphone" && checkCellphone) return item
            })

            if (keyValuesError.length === 2) {
                const item1 = keyValuesError[0]
                const item2 = keyValuesError[1]

                setErro({
                    ...formsError,
                    [item1]: { ...formsError[item1], error: true },
                    [item2]: { ...formsError[item2], error: true },
                })
            } else {
                const item1 = keyValuesError[0]
                setErro({
                    ...formsError,
                    [item1]: { ...formsError[item1], error: true }
                })
            }
        }
    }

    const createSolicitation = async () => {
        const serviceAux = servicesState.filter(item => services[solicitation.service] === item.name)[0]

        if (serviceAux.difficulty.includes(solicitation.difficulty)) {
            const response = await solicitationService.setSolicitation({solicitation: solicitation, leadName: leadState.name})
            if (response.status === 200) {
                setLead(response.uuidLead)
                dispatch(setUuidLead(response.uuidLead))
                dispatch(recreateSolicitation(response.solicitation))
                dispatch(changeContext({
                    step: "maps",
                    active: "solicitation",
                    action: "newSolicitation"
                }))
            }
        } else {
            dispatch(openModal({modal: "impossibleService", value: true}))
        }
    }

    const handleCreateSolicitation = async () => {
        event.registerEvent({
            type: "completedForms",
            difficulty: solicitation.difficulty,
            cellphone: solicitation.cellphone,
            service: solicitation.service,
            leadName: leadState.name,
            uuidLead: leadState.uuid,
            address: solicitation.current_location.formattedAddress,
            solicitationType: solicitation.type,
            vehicle: solicitation.vehicle,
            placa: solicitation.placa
        })

        await createSolicitation()
    }

    const handleNextStep = async () => {
        setLoadignSubmit(true)
        if (!disableButton()) {
            await handleCreateSolicitation()
        } else {
            showError()
        }
        setLoadignSubmit(false)
    }

    const redirectToChat = () => {
        window.location.href = `https://wa.me/553131577411?text=Preciso de ajuda.`
    }

    return (
        <div className='lead-forms-container'>
            <div className='lead-forms-header'>
                <img alt="" src={ContactVector} />
            </div>
            <div className='lead-forms-content'>
                <div className='lead-forms-input-item'>
                    <div className='lead-forms-title-input'>
                        <span>Nome:</span>
                    </div>
                    <InputText 
                        label="Informe o seu nome"
                        value={leadState.name}
                        onBlur={() => checkName()}
                        error={formsError.name.error}
                        helperText={formsError.name.message}
                        onChange={(event) => dispatch(setLeadName(event.target.value))}
                        style={{
                            marginTop: 8
                        }}
                    />
                </div>
                <div className='lead-forms-input-item'>
                    <div className='lead-forms-title-input'>
                        <span>Telefone de contato:</span>
                    </div>
                    <InputText 
                        mask="cellphone"
                        value={solicitation.cellphone}
                        label="Informe o seu telefone decontato"
                        placeholder={"(DD) 99999-9999"}
                        onBlur={checkCellphone}
                        error={formsError.cellphone.error}
                        helperText={formsError.cellphone.message}
                        onChange={(event) => dispatch(changeSolicitation({
                            column: "cellphone", 
                            value: event.target.value
                        }))}
                        style={{
                            marginTop: 8
                        }}
                    />
                </div>
                <div className='lead-forms-input-item'>
                    <div className='lead-forms-title-input'>
                        <span>Nos conte um breve relato do ocorrido: (Opcional)</span>
                    </div>
                    <InputText 
                        multiline
                        rows={2}
                        value={solicitation.report}
                        label={"Digite..."}
                        onChange={(event) => dispatch(changeSolicitation({
                            column: "report", 
                            value: event.target.value
                        }))}
                        style={{
                            marginTop: 8
                        }}
                    />
                </div>
            </div>
            <div className='lead-forms-footer'>
                <button 
                    className='location-forms-goback'
                    onClick={() => dispatch(changeContext({
                        active: "solicitation",
                        step: "forms",
                        action: "step2"
                    }))}
                >
                    Voltar
                </button>
                <Button 
                    disabled={disableButton()}
                    onClick={handleNextStep}
                    loading={loadingSubmit}
                    label="Procurar prestadores!!"
                    style={{width: "80%"}}
                />
            </div>
            <div className='lead-forms-footer-hr'/>
            <Button 
                kind="secondary"
                onClick={redirectToChat}
                label="Solicitar pelo WhatsApp"
                style={{marginTop: 8}}
            />
        </div>
    )
}

export default Step3