import api from '../services/api';

const getResponse = (user, token, message) => {
    return {
        user: user,
        token: token,
        message: message
    }
}

export const signIn = async(user) => {
    try {
        const response = await api.post('/signin/', user)
        if (response.status === 200) {
            return getResponse(response.data.User, response.data.Token, response.data.Message)
        }
        return getResponse(undefined, undefined, response.data.Message)
    } catch (error) {
        console.log(error)
    }
}

export const signInGoogle = async(user) => {
    try {
        const response = await api.post('/signin/google', user)
        if (response.status === 200) {
            return getResponse(response.data.User, response.data.Token, response.data.Message)
        }
        return getResponse(undefined, undefined, response.data.Message)
    } catch (error) {
        console.log(error)
    }
}

export const signUp = async(newUser) => {
    try {
        const response = await api.post('/user/', newUser)
        if (response.status === 200) {
            return getResponse(response.data.User, response.data.Token, response.data.Message)
        }
        return getResponse(undefined, undefined, response.data.Message)
    } catch (error) {
        console.log(error)
    }
}

export const updateUser = async(newUser) => {
    try {
        const response = await api.put('/user/', newUser)
        if (response.status === 200) {
            return getResponse(response.data.Item, '', response.data.Message)
        }
        return getResponse(response.data.Item, undefined, response.data.Message)
    } catch (error) {
        console.log(error)
    }
}

export const refreshToken = async (user) => {
    try {
        const response = await api.post('/refreshToken/', user)
        if (response.status === 200) {
            return getResponse(response.data.User, response.data.Token, response.data.Message)
        }
        return getResponse(undefined, undefined, response.data.Message)
    } catch (error) {
        console.log(error)
    }
}