import { useState } from "react"
import { Modal } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../../actions/modal"

import Button from "../../Button"

import LocationVector from '../../../assets/images/vector/location.svg'

import './styles.css'

const RequestGeolocationModal = ({
    onAllow,
    onDeny
}) => {
    const dispatch = useDispatch()

    const modalActive = useSelector(state => state.modal.requestGeolocation)

    const handleClose = () => {
        dispatch(openModal({modal: "requestGeolocation", value: false}))
    }

    const handleAllow = () => {
        onAllow()
        handleClose()
    }

    const handleDeny = () => {
        onDeny()
        handleClose()
    }

    return (
        <Modal
            open={modalActive}
            onClose={handleClose}
        >
            <div className="modal-location-request-container">
                <div className="modal-location-request-title-content">
                    <h2>Permissão de localização</h2>
                    <span>
                        Ao solicitar um novo serviço em nosso sistema, 
                        precisamos saber qual a localização atual do seu veículo 
                        para que possamos procurar o prestador disponível mais perto de você.
                    </span>
                    <img src={LocationVector} alt="location"/>
                </div>
                <div className="modal-location-request-button-content">
                    <Button 
                        kind="secondary" 
                        label="Não permitir" 
                        onClick={handleDeny}
                    />
                    <Button 
                        kind="primary" 
                        label="Permitir" 
                        onClick={handleAllow}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default RequestGeolocationModal
