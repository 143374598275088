import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: "user",
    initialState: {
        type: "lead"
    },
    reducers: {
        updateUser: (state, action) => {
            const { payload } = action
            
            state.type = "user"
            state.uuid = payload.user.uuid
            state.data = payload.user
        },
    }
})

// Action creators are generated for each case reducer function
export const { 
    updateUser
} = userSlice.actions

export default userSlice.reducer