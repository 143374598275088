import Avatar from '@mui/material/Avatar';

const CustomAvatar = ({
    label,
    size
}) => {

    const getAvatarLabel = (text) => {
        return text.split(/\s/).filter((value, index) => {
            if (index === 0 || index === (text.split(/\s/).length -1)){
                return value
            }
        }).reduce((response,word)=> response+=word.slice(0,1),'').toUpperCase()
    }

    return (
        <Avatar 
            sx={{ width: size, height: size }}
        >
            {getAvatarLabel(label)}
        </Avatar>
    )
}

export default CustomAvatar;