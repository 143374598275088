import { configureStore } from '@reduxjs/toolkit'

import servicesProposalsSlice from './actions/serviceProposals'
import solicitationReducer from './actions/solicitations'
import servicesReducer from './actions/services'
import leadSlice from './actions/lead'
import userSlice from './actions/user'
import modalSlice from './actions/modal'
import bottomSheetSlice from './actions/bottomSheets'
import contextSilce from './actions/context'
import paymentsSlice from './actions/payments'
import messagesSlice from './actions/messages'
import webSocketSlice from './actions/webScocket'

export default configureStore({
  reducer: {
    solicitation: solicitationReducer,
    services: servicesReducer,
    lead: leadSlice,
    user: userSlice,
    serviceProposals: servicesProposalsSlice,
    modal: modalSlice,
    bottomSheets: bottomSheetSlice,
    context: contextSilce,
    payments: paymentsSlice,
    messages: messagesSlice,
    webSocket: webSocketSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: false
    }),
})