import { useDispatch, useSelector } from "react-redux";
import { changeContext } from "../../../actions/context";

import { QRCodeSVG } from 'qrcode.react';

import Title from "../../../components/Title";

import './styles.css'
import Button from "../../../components/Button";
import * as webSocket from '../../../services/wss'
import { setWebSocketConnection, setWebSocketReady } from "../../../actions/webScocket";
import { changeSeriveProposal, insertServiceProposals } from "../../../actions/serviceProposals";
import { useEffect, useState } from "react";

const sleep = time => new Promise(resolve => setTimeout(() => resolve(), time))

const PixPayment = () => {
    const dispatch = useDispatch()

    const serviceProposal = useSelector(state => state.serviceProposals.selected)
    const contextAction = useSelector(state => state.context.action)
    const solicitation = useSelector(state => state.solicitation)
    const user = useSelector(state => state.user)

    const [loadingCopy, setLoadingCopy] = useState(false)

    const handleGoBack = () => {
        dispatch(changeContext({
            active: "payments",
            step: "checkout",
            action: contextAction.userType
        }))
    }

    const startWebsocket = () => {
        const connection = webSocket.connect(user.uuid, user.type)

        connection.onopen = () => { dispatch(setWebSocketReady(true)); };

        connection.onmessage = async (event) => {
            const data = JSON.parse(event.data)
            if (data.uuidSolicitation === solicitation.uuid) {
                if (data.action === "paymentStatus") {
                    if (data.paymentStatus === "order.paid") {
                        dispatch(insertServiceProposals(data.serviceProposals))
                
                        dispatch(changeSeriveProposal({
                            column: "status",
                            value: "approved"
                        }))

                        dispatch(changeContext({
                            active: "payments",
                            step: "success",
                            action: ""
                        }))
                    }
                }
            }
        }

        dispatch(setWebSocketConnection(connection))
    }

    const copyLink = async () => {
        setLoadingCopy(true)
        navigator.clipboard.writeText(contextAction.qrcode)
        await sleep(300)
        setLoadingCopy(false)
    }

    const redirectToChat = () => {
        window.location.href = `https://wa.me/553131577411?text=Preciso de ajuda.`
    }

    useEffect(() => {
        startWebsocket()
    }, [])

    return (
        <>
            <Title label="Pagamento Pix" goBack={handleGoBack}/>
            <div className="pix-payment-container">
                <div className="pix-payment-summary-content">
                    <span>Valor do serviço</span>
                    <label>
                        {
                            Number(serviceProposal.price).toLocaleString(
                                "pt-BR", {style: "currency", currency: "BRL"}
                            )
                        }
                    </label>
                </div>
                <div className="pix-payment-qrcode-content">
                    <QRCodeSVG value={contextAction.qrcode} fgColor="#2D2D2D" size={220}/>
                </div>
                <Button 
                    loading={loadingCopy}
                    onClick={copyLink}
                    label={"Clique para copiar o código"} 
                    style={{width: "70%", marginTop: 16}}
                />
                <Button 
                    kind="secondary"
                    onClick={redirectToChat}
                    label="Podemos te ajudar?"
                    style={{width: "70%", marginTop: 16}}
                />
            </div>
        </>
    )
}

export default PixPayment;