import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BottomSheet } from "react-spring-bottom-sheet"
import { openBottomSheet } from "../../../actions/bottomSheets"
import { openModal } from "../../../actions/modal"

import './styles.css'
import Button from "../../Button"

const CancelSolicitationBottomSheet = ({
    open
}) => {
    const dispatch = useDispatch()
    const sheetRef = useRef()

    const solicitation = useSelector(state => state.solicitation)

    const handleOpenModal = (modal) => {
        dispatch(openBottomSheet({bottomSheet: "solicitation", value: false}))
        dispatch(openModal({modal: modal, value: true}))
    }

    const formatDate = (date) => {
        const newDate = new Date(date)
        const newDateUtc3 = new Date(newDate.setHours(newDate.getHours() -3))
        
        return newDateUtc3.toLocaleDateString("pt-br") + ' ' + newDateUtc3.toLocaleTimeString("pt-br")
    }

    const handleReloadPage = () => {
        window.location.href = '/'
    }

    return (
        <BottomSheet
            className="bottom-sheet-container"
            ref={sheetRef}
            open={open}
            defaultSnap={({ maxHeight }) => maxHeight / 4}
            snapPoints={({ maxHeight, minHeight }) => [
                maxHeight - maxHeight / 10,
                maxHeight / 4,
                maxHeight * 0.6,
                minHeight
            ]}
        >
            <div className='bottom-sheet-content'>
                <div className='bottom-sheet-cancel-title-content'>
                    <label>Solicitação cancelada com sucesso</label>
                    <span>{`Solicitação cancelada em: ${formatDate(solicitation.canceledAt)}`}</span>
                </div>
                <div className="bottom-sheet-cancel-describe">
                    {solicitation.isCapturePayments ? (
                        <span>
                            A solicitação foi cancelada 60 segundos após o prestador aprovar o serviço.
                            Portanto, foi cobrado <strong>{Number(solicitation.capturePaymentsValue / 100).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</strong> em sua fatura referente ao deslocamento total do prestador até você.
                            Caso tenha sido efetuada alguma cobrança em sua fatura, o valor será reembolsado. Se você tiver alguma dúvida, entre em contato conosco.
                        </span>
                    ) : (
                        <span> 
                            Esta solicitação foi cancelada antes da aprovação de um prestador. Por este motivo não houve cobrança de multa.
                        </span>
                    )}
                </div>
                <div className='bottom-sheet-button-content'>
                    <Button 
                        kind="primary" 
                        label="Voltar ao menu inicial"
                        onClick={handleReloadPage}
                    />
                </div>
            </div>
        </BottomSheet>
    )
}
export default CancelSolicitationBottomSheet