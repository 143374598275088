import MastercardIcon from '../../../assets/images/icon/mastercard.svg'
import VisaIcon from '../../../assets/images/icon/visa.svg'

import './styles.css'

const PaperCreditCard = ({
    card,
    active,
    onSelected
}) => {
    const cardBrandIcon = {
        mastercard: MastercardIcon,
        visa: VisaIcon
    }

    return (
        <>
            <button 
                className={`paper-credit-card-container ${active && "active"}`} 
                onClick={onSelected}
            >
                <img alt="card_brand" src={cardBrandIcon[card.brand.toLowerCase()]}/>
                <div className={`paper-credit-card-content ${active && "active"}`}>
                    <label >{card.brand}</label>
                    <label>{card.first_six_digits} **** {card.last_four_digits}</label>
                </div>
            </button>
        </>
    )
}

export default PaperCreditCard;