import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { OutlinedInput } from '@mui/material';

const CustomSelect = ({
    label,
    options,
    value,
    style,
    onChange,
    error,
    helperText
}) => {

    return (
        <FormControl fullWidth style={style}>
            <InputLabel size='small'>{label}</InputLabel>
            <Select 
                size="small"
                label={label}
                onChange={onChange}
                value={value}
                error={error}
                input={<OutlinedInput label={label} />}
            >
                {options.map((item, index) => {
                    return <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                })}
            </Select>
            {error && <span style={{fontSize: 10, color: "#d32f2f", marginLeft: 6, marginTop: 6}}>{helperText}</span>}
        </FormControl>
    )
}

export default CustomSelect;