import { useEffect, useState } from 'react'

import  {
    useMap,
    useMapsLibrary
} from '@vis.gl/react-google-maps'

export const Directions = ({
    origin,
    destination,
    onPlotRoute
}) => {

    const map = useMap()
    const routesLibrary = useMapsLibrary("routes")

    const [directionsService, setDirectionsService] = useState()
    const [directionsRenderer, setDirectionsRenderer] = useState()

    const [routes, setRoutes] = useState([])

    useEffect(() => {
        if(!routesLibrary || !map) return
        
        setDirectionsService(new routesLibrary.DirectionsService())
        setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map: map, suppressMarkers: true }))
    }, [routesLibrary, map, destination])

    useEffect(() => {
        if (routes.length === 0) return 
        onPlotRoute({
            distance: routes[0].legs[0].distance,
            duration: routes[0].legs[0].duration,
            start_address: routes[0].legs[0].start_address,
            end_address: routes[0].legs[0].end_address
        })
    }, [routes])

    useEffect(() => {
        if (!directionsService || !directionsRenderer) return

        directionsService.route({
            origin: origin,
            destination: destination,
            travelMode: "DRIVING",
            provideRouteAlternatives: true,
        }).then(reponse => {
            directionsRenderer.setDirections(reponse)
            setRoutes(reponse.routes)
        })
    }, [directionsService, directionsRenderer])
}