import { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from "../../../../actions/user";

import * as auth from '../../../../services/auth';
import * as event from '../../../../services/event';

import Button from "../../../../components/Button"
import InputPassword from "../../../../components/Input/Password"
import PaperUser from "../../../../components/Paper/User"
import CustomAlert from "../../../../components/Alert";
import { changeSolicitation } from "../../../../actions/solicitations";
import { changeContext, changeStep } from "../../../../actions/context";
import { changeSeriveProposal } from "../../../../actions/serviceProposals";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

const SignInStorageUser = ({
    user,
    form,
    setUser,
    changeForm
}) => {
    const dispatch = useDispatch()

    const leadState = useSelector(state => state.lead)
    const solicitation = useSelector(state => state.solicitation)
    const contextAction = useSelector(state => state.context.action)
    
    const [loadingButton, setLoadingButton] = useState(false)
    const [error, setError] = useState({
        signin: {error: false, message: "Senha inválida."}
    })

    const refreshToken = async () => {
        setLoadingButton(true)

        const body = {
            email: user.user.email,
            password: form.password,
            validation_type: "password"
        }

        const response = await auth.refreshToken(body)
        if(response.message === "SUCCESS") {
            setUser(response)
            
            dispatch(updateUser(response))

            dispatch(changeSolicitation({
                column: "uuidUser",
                value: response.user.uuid
            }))

            dispatch(changeSeriveProposal({
                column: "uuidUser",
                value: response.user.uuid
            }))

            if (contextAction === "saveConsumer") {
                dispatch(changeContext({
                    step: "maps",
                    action: "getSolicitation",
                    active: "solicitation"
                }))
            } else {
                dispatch(changeContext({
                    active: "payments",
                    step: "checkout",
                    action: "user"
                }))
            }

            event.registerEvent({
                type: "completedSignIn",
                uuidLead: leadState.uuid,
                uuidUser: response.user.uuid,
                solicitation: solicitation
            })
        } else {
            setError({...error, signin: {...error.signin, error: true}})
        }

        setLoadingButton(false)
    }

    const handleGoToRegister = () => {
        dispatch(changeStep("signUpStep1"))
    }

    const handleGoogleAuth = async (credentialResponse) => {        
        const user = jwtDecode(credentialResponse.credential)
        const response = await auth.signInGoogle({
            name: user.name,
            email: user.email,
            photo: user.picture,
            provider: "googleOAuth"
        })

        if(response.message === "SUCCESS") {
            setUser(response)
        
            dispatch(updateUser(response))
        
            dispatch(changeSolicitation({
                column: "uuidUser",
                value: response.user.uuid
            }))
          
            dispatch(changeSeriveProposal({
                column: "uuidUser",
                value: response.user.uuid
            }))
        
            dispatch(changeContext({
                active: "payments",
                step: "checkout",
                action: "user"
            }))
        }
    }

    return (
        <>
            <div className='auth-content'>
                <span className='auth-subtitle'>Bem vintdo à Keeper</span>
                <h2>Entre em sua conta</h2>
                <div 
                    className="auth-signup-user-content"
                    style={{margin: 0, marginTop: 12}}
                >
                    <PaperUser 
                        trash
                        user={user.user}
                        onClick={() => dispatch(changeStep("withoutUser"))}
                    />
                </div>
                <div className='auth-forms-content' style={{gridTemplateRows: "1fr 1fr"}}>
                    <InputPassword 
                        label="Seu senha"
                        value={form.password}
                        onChange={(event) => changeForm("password", event.target.value)}
                        style={{marginBottom: 12}}
                    />
                    <Button 
                        loading={loadingButton}
                        label="Entrar na minha conta"
                        onClick={refreshToken}
                    />
                    <div className="auth-singin-provider-content">
                        <GoogleLogin
                            useOneTap
                            context="signup"
                            onSuccess={credentialResponse => handleGoogleAuth(credentialResponse)}
                            onError={() => console.log('Login Failed')}
                            locale="pt_BR"
                        />
                    </div>
                </div>
                <button className='auth-link' onClick={() => dispatch(changeStep("passwordStep1"))}>Esqueceu a senha?</button>
                <div className='auth-button-content'>
                    <span>Seu primeiro acesso?</span>
                    <Button 
                        kind="secondary"
                        label="Cadastre-se"
                        onClick={handleGoToRegister}
                    />
                </div>
            </div>
            <CustomAlert 
                show={error.signin.error}
                message={error.signin.message}
                onClose={() => setError({...error, signin: {...error.signin, error: false}})}
            />
        </>
    )
}

export default SignInStorageUser