import api from '../services/api';

export const setSolicitation = async (solicitation) => {
    try {
        const response = await api.post('/solicitation', solicitation)

        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log('setSolicitation',error)
        return false
    }
}

export const getSolicitationsByUser = async (user, userType) => {
    try {
        const response = await api.get('/solicitation/user/', {params: {
            userType: userType,
            user: user
        }})

        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log('getSolicitationsByUser', error.message)
        return false
    }
}

export const cancelSolicitation = async (uuidUser, solicitation, reason) => {
    try {
        const payload = {
            uuidUser: uuidUser,
            solicitation: solicitation,
            reasonForCancellation: reason
        }

        const response = await api.post('/solicitation/cancel', payload)
        if (response.data.message === "success") {
            return true
        }
        return false
    } catch (error) {
        console.log('cancelSolicitation', error)
    }
}

export const finishSolicitation = async (solicitation) => {
    try {
        const response = await api.post('/solicitation/finish', solicitation).catch(reason => console.log(reason))
        if (response.data.message === "success") {
            return true
        }
        return false
    } catch (error) {
        console.log('finishSolicitation', error)
    }
}

export const getSolicitation = async (uuidSolicitation) => {
    try {
        const response = await api.get(`/solicitation?id=${uuidSolicitation}`)
        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log('getSolicitation',error)
        return false
    }
}

export const getServicesActive = async () => {
    try {
        const response = await api.get('service/active')
        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log("getServicesActive", error)
    }
}

export const refuseServiceProposal = async (serviceProposal) => {
    try {
        const response = await api.post('serviceProposals/refuse', serviceProposal)
        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log("getServicesActive", error)
    }
}

export const updateSolicitation = async (solicitation) => {
    try {
        const response = await api.put('solicitation/', solicitation)
        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log("getServicesActive", error)
    }
}

export const getServiceProposalsBySolicitation = async (uuidSolicitation) => {
    try {
        const response = await api.get('/serviceProposals/getBySolicitation/', {params: {
            uuidSolicitation: uuidSolicitation
        }})
        
        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log("getServicesActive", error)
    }
}