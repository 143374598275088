import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as solicitationService from '../../../../services/solicitations'
import * as locationService from '../../../../services/location'
import * as event from '../../../../services/event'

import { changeCurrentLocation, changeDestinyLocation, changeSolicitation, recreateSolicitation } from '../../../../actions/solicitations'
import { changeContext } from '../../../../actions/context'
import { setUuidLead } from '../../../../actions/lead'
import { openModal } from '../../../../actions/modal'

import Button from '../../../../components/Button'
import InputText from '../../../../components/Input/Text'
import ConfirmLocationMOdal from '../../../../components/Modal/ConfirmLocation'
import RequestGeolocationModal from '../../../../components/Modal/RequestGeolocation'

import VehicleLocationVector from '../../../../assets/images/vector/vehicle-location.svg'

import './styles.css'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'

const Step2 = () => {
    const dispatch = useDispatch()
    const [__, setLead, _] = useLocalStorage("lead", null)

    const leadState = useSelector(state => state.lead)
    const solicitation = useSelector(state => state.solicitation)
    const servicesState = useSelector(state => state.services.auto)
    
    const [editLocation, setEditLocation] = useState(false)
    const [loadingSubmit, setLoadignSubmit] = useState(false)
    const [locationError, setLocationError] = useState(false)
    const [loadingLocation, setLoadingLocation] = useState(false)

    const [reboqueFormsError, setReboqueFormsError] = useState({
        wheelsConditions: {error: false, message: "A resposta não pode ser vazia."},
        destinyLocation: {error: false, message: "Precisamos saber para qual endereço vamos levar o veículo."},
    })

    const services = {
        "mecanico": 'Mecânico',
        "bateria": 'Socorro Bateria',
        "chaveiro": 'Chaveiro',
        "combustivel": 'Combustível',
        "pneu": 'Troca de Pneus',
        "reboque": "Reboque"
    }

    const formatAddress = (address) => {
        const street = address.filter(item => item.types.includes("route")).map(item => item.short_name)[0]
        const streetNumber = address.filter(item => item.types.includes("street_number")).map(item => item.short_name)[0]
        const district = address.filter(item => item.types.includes("sublocality_level_1")).map(item => item.short_name)[0]
        const city = address.filter(item => item.types.includes("administrative_area_level_2")).map(item => item.short_name)[0]
        const state = address.filter(item => item.types.includes("administrative_area_level_1")).map(item => item.short_name)[0]

        return [street, streetNumber, district, city, state]
    }

    const printStreet = (place) => {
        if (loadingLocation) return "Localizando..."
        else if (place) return `${place.street}, ${place.streetNumber}${place.district ? ", " + place.district : ""}`
        return "Não conseguimos identificar sua localização. Verifique o endereço digitado e tente pesquisar novamente."
    }

    const printState = (place) => {
        if (loadingLocation) return ""
        else if (place) return ` - ${place.city}, ${place.state}`
        return ""
    }

    const handleGetLocation = async (locationType) => {
        console.log("opa")
        const currentLocation = locationType === "current_location" ? solicitation.current_location.formattedAddress : solicitation.destinyLocation.formattedAddress
        const response = await locationService.getLocations(currentLocation)

        if (response && response.status === "OK") {
            const result = response.results[0]
            const addressComponents = result.address_components
            const geometry = result.geometry

            const address = formatAddress(addressComponents)

            dispatch(changeSolicitation({
                column: locationType, 
                value: {
                    street: address[0],
                    streetNumber: address[1],
                    district: address[2],
                    city: address[3],
                    state: address[4],
                    latitude: Number(geometry.location.lat),
                    longitude: Number(geometry.location.lng),
                    formattedAddress: result.formatted_address
                }
            }))

            setLocationError(false)
        }
    }

    const handleGeocodingReverse = async (position) => {
        const latitude = position.coords.latitude
        const longitude = position.coords.longitude

        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCBFaUHIxOMqWmpSBrrWaF3uJbNDPSeXKE`

        const response = await fetch(new Request(url)).then((response) => response.json())

        if (response.status === "OK") {
            const result = response.results[0]

            const addressComponents = result.address_components
            const geometry = result.geometry

            const address = formatAddress(addressComponents)

            dispatch(changeSolicitation({
                column:"current_location", 
                value: {
                    street: address[0],
                    streetNumber: address[1],
                    district: address[2],
                    city: address[3],
                    state: address[4],
                    latitude: Number(geometry.location.lat),
                    longitude: Number(geometry.location.lng),
                    formattedAddress: result.formatted_address
                }
            }))
        } else {
            setEditLocation(true)
            setLocationError(true)
        }
        setLoadingLocation(false)
    }

    const getLocationPermission = () => {
        navigator.geolocation.getCurrentPosition(position => handleGeocodingReverse(position))
    }

    const denyLocationPermission = () => {
        setEditLocation(true)
        setLoadingLocation(false)
        setLocationError(true)
    }

    const handleRegisterEventForms = (eventName, value) => {
        event.registerEvent({
            type: eventName,
            uuidLead: leadState.uuid,
            eventData: value
        })
    }

    const disableButton = () => {
        const checkLocation = solicitation.current_location.latitude === 0

        const checkDestination = solicitation.service === "reboque" && (
            solicitation.destinyLocation.latitude === 0
        )

        if (checkLocation || checkDestination) {
            return true
        }
        return false
    }

    const showError = () => {
        const checkLocation = solicitation.current_location.latitude === 0

        const checkDestination = solicitation.service === "reboque" && (
            solicitation.destinyLocation.latitude === 0
        )

        if (checkLocation || checkDestination) {
            if (checkLocation) {

            }

            if (checkDestination) {
                setReboqueFormsError({
                    ...reboqueFormsError,
                    destinyLocation: {...reboqueFormsError.destinyLocation, error: true}
                })
            }
        }
    }

    const createSolicitation = async () => {
        const serviceAux = servicesState.filter(item => services[solicitation.service] === item.name)[0]

        if (serviceAux.difficulty.includes(solicitation.difficulty)) {
            const response = await solicitationService.setSolicitation({solicitation: solicitation, leadName: leadState.name})
            if (response.status === 200) {
                setLead(response.uuidLead)
                dispatch(setUuidLead(response.uuidLead))
                dispatch(recreateSolicitation(response.solicitation))
                dispatch(changeContext({
                    step: "maps",
                    active: "solicitation",
                    action: "newSolicitation"
                }))
            }
        } else {
            dispatch(openModal({modal: "impossibleService", value: true}))
        }
    }

    const handleCreateSolicitation = async () => {
        event.registerEvent({
            type: "completedForms",
            difficulty: solicitation.difficulty,
            cellphone: solicitation.cellphone,
            service: solicitation.service,
            leadName: leadState.name,
            uuidLead: leadState.uuid,
            address: solicitation.current_location.formattedAddress,
            solicitationType: solicitation.type,
            vehicle: solicitation.vehicle,
            placa: solicitation.placa
        })

        await createSolicitation()
    }

    const handleNextStep = async () => {
        setLoadignSubmit(true)
        if (!disableButton()) {
            if (!editLocation) {
                dispatch(openModal({modal: "confirmLocation", value: true}))
            } else {
                handleRegisterEventForms("location", solicitation.current_location)
                dispatch(changeContext({
                    active: "solicitation",
                    step: "forms",
                    action: "step3"
                }))
            } 

            //if (!editLocation) {
            //    dispatch(openModal({modal: "confirmLocation", value: true}))
            //} else {
            //    await handleCreateSolicitation()
            //} 
        } else {
            showError()
        }
        setLoadignSubmit(false)
    }

    const redirectToChat = () => {
        window.location.href = `https://wa.me/553131577411?text=Preciso de ajuda.`
    }

    useEffect(() => {
        (async () => {
            setLoadingLocation(true)
            if(navigator.geolocation) {
                navigator.permissions.query({name: "geolocation"}).then((result) => {
                    if (result.state === "granted") {
                        getLocationPermission()
                    } else if (result.state === "prompt") {
                        dispatch(openModal({
                            modal: "requestGeolocation",
                            value: true
                        }))
                    } else if (result.state === "denied") {
                        denyLocationPermission()    
                    }

                    result.onchange = () => {
                        if (result.state === "denied") denyLocationPermission()
                        else if (result.state === "granted") getLocationPermission()
                    }
                })
            } else {
                setEditLocation(true)
                setLoadingLocation(false)
            }
        })()
    }, [])

    return (
        <>
            <RequestGeolocationModal 
                onAllow={getLocationPermission}
                onDeny={denyLocationPermission}
            />
            <ConfirmLocationMOdal 
                onConfirm={() => {
                    handleRegisterEventForms("location", solicitation.current_location);
                    dispatch(changeContext({
                        active: "solicitation",
                        step: "forms",
                        action: "step3"
                    }))
                }}
            />
            <div className='location-forms-container'>
                <div className='location-forms-header'>
                    <img alt="" src={VehicleLocationVector} />
                </div>
                <div className='location-forms-content'>
                    <div>
                        <div className='location-forms-title-input'>
                            <span>Qual a localização atual do veículo?</span>
                            <button onClick={() => setEditLocation(true)}>Editar</button>
                        </div>
                        {editLocation &&
                            <InputText 
                                value={solicitation.current_location.formattedAddress}
                                label={"Digite a localização atual do veículo"}
                                onBlur={() => handleGetLocation("current_location")}
                                error={locationError}
                                helperText={"Endereço inválido"}
                                onChange={(event) => dispatch(changeCurrentLocation({
                                    column:"formattedAddress", 
                                    value: event.target.value
                                }))}
                                style={{
                                    marginTop: 8
                                }}
                            />
                        }
                        <div className='location-result-content'>
                            {locationError ? (
                                <span>Não conseguimos identificar sua localização. Infome sua localização manualmente.</span>
                            ) : (
                                <span>{printStreet(solicitation.current_location)}{printState(solicitation.current_location)}</span>
                            )}
                        </div>
                    </div>
                    {solicitation.service === "reboque" &&
                        <div style={{marginTop: 16}}>
                            <div className='location-forms-title-input'>
                                <span>Qual a destino do veículo?</span>
                            </div>
                            <InputText 
                                value={solicitation.destinyLocation.formattedAddress}
                                label={"Digite o destino do veículo"}
                                error={reboqueFormsError.destinyLocation.error}
                                helperText={reboqueFormsError.destinyLocation.message}
                                onBlur={() => handleGetLocation("destinyLocation")}
                                onChange={(event) => dispatch(changeDestinyLocation({
                                    column:"formattedAddress", 
                                    value: event.target.value
                                }))}
                                style={{
                                    marginTop: 8
                                }}
                            />
                            <div className='location-result-content'>
                                {solicitation.destinyLocation.formattedAddress && solicitation.destinyLocation.street !== "" &&
                                    <span>{printStreet(solicitation.destinyLocation)}{printState(solicitation.destinyLocation)}</span>
                                }
                            </div>
                        </div>
                    }
                </div>
                <div className='location-forms-footer'>
                    <button 
                        className='location-forms-goback'
                        onClick={() => dispatch(changeContext({
                            active: "solicitation",
                            step: "forms",
                            action: "step1"
                        }))}
                    >
                        Voltar
                    </button>
                    <Button 
                        disabled={disableButton()}
                        onClick={handleNextStep}
                        loading={loadingSubmit}
                        label="Próximo: Informações de contato"
                        style={{width: "80%"}}
                    />
                </div>
                <div className='lead-forms-footer-hr'/>
                <Button 
                    kind="secondary"
                    onClick={redirectToChat}
                    label="Solicitar pelo WhatsApp"
                    style={{marginTop: 8}}
                />
            </div>
        </>
    )
}

export default Step2