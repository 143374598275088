import { useEffect, useState } from 'react'

import Brand from '../../assets/brand/logo-branco.svg'

import SignInStorageUser from './SignIn/StoragedUser'
import SignInWithoutUser from './SignIn/WithoutUser'
import SignUpStep1 from './SignUp/Step1'
import SignUpStep2 from './SignUp/Step2'
import PasswordStep1 from './Password/Step1'
import PasswordStep2 from './Password/Step2'
import PasswordStep3 from './Password/Step3'

import './styles.css'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { useDispatch, useSelector } from 'react-redux'
import { changeStep } from '../../actions/context'

const Authentication = () => {
    const dispatch = useDispatch()

    const contextControler = useSelector(state => state.context)
    const [user, setUser, getUser] = useLocalStorage("user", null);

    const [signInForm, setSignInForm] = useState({
        email: "",
        password: "",
    })
    const [signUpForm, setSignUpForm] = useState({
        name: "",
        email: "",
        password: "",
        confirmPassword: ""
    })
    const [passwordForm, setPasswordForm] = useState({
        uuid: '',
        email: '',
        password: '',
        confirmPassword: ''
    })

    const handlePageSelected = (page) => {
        if (page === "signIn") {
            dispatch(changeStep(user ? "storageUser" : "withoutUser"))
        } else {
            dispatch(changeStep(page))
        }
    }

    const handleSignUpForm = (column, value) => {
        setSignUpForm({
            ...signUpForm,
            [column]: value 
        })
    }

    const handleSignInForm = (column, value) => {
        setSignInForm({
            ...signInForm,
            [column]: value 
        })
    }

    const handlePasswordForm = (column, value) => {
        setPasswordForm({
            ...passwordForm,
            [column]: value 
        })
    }

    useEffect(() => { if (contextControler.step === "") handlePageSelected("signIn") }, [contextControler])

    const pages = {
        withoutUser: (
            <SignInWithoutUser form={signInForm} changeForm={handleSignInForm} setUser={setUser} />
        ), 
        storageUser: (
            <SignInStorageUser form={signInForm} changeForm={handleSignInForm} setUser={setUser}  user={user}/>
        ),
        signUpStep1: (
            <SignUpStep1 form={signUpForm} changeForm={handleSignUpForm}/>
        ),
        signUpStep2: (
            <SignUpStep2 form={signUpForm} changeForm={handleSignUpForm} setUser={setUser} />
        ),
        passwordStep1: (
            <PasswordStep1 form={passwordForm} changeForm={handlePasswordForm}/>
        ),
        passwordStep2: (
            <PasswordStep2 form={passwordForm} changeForm={handlePasswordForm}/>
        ),
        passwordStep3: (
            <PasswordStep3 form={passwordForm} changeForm={handlePasswordForm}/>
        )
    }

    return (
        <div className="auth-container">
            <div className='auth-brand-content'>
                <img src={Brand} alt='brand'/>
            </div>
            {pages[contextControler.step]}
        </div>
    )
}

export default Authentication;