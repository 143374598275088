import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './store'
import Router from './routes';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux'
import { Analytics } from '@vercel/analytics/react';
import { BrowserRouter } from "react-router-dom";
import { SpeedInsights } from '@vercel/speed-insights/react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Router />
        <Analytics />
        <SpeedInsights />
      </GoogleOAuthProvider>
    </Provider>
  </BrowserRouter>
);
reportWebVitals();
