import { useState } from "react"
import { updateUser } from "../../../../actions/user";
import { useDispatch, useSelector } from "react-redux";

import * as auth from '../../../../services/auth';
import * as event from '../../../../services/event';

import Button from "../../../../components/Button"
import PaperUser from "../../../../components/Paper/User"
import InputPassword from "../../../../components/Input/Password"
import CustomAlert from "../../../../components/Alert";
import { changeContext, changeStep } from "../../../../actions/context";
import { changeSolicitation } from "../../../../actions/solicitations";
import { changeSeriveProposal } from "../../../../actions/serviceProposals";

const SignUpStep2 = ({
    form,
    setUser,
    changeForm
}) => {
    const dispatch = useDispatch()

    const leadState = useSelector(state => state.lead)
    const solicitation = useSelector(state => state.solicitation)
    const contextAction = useSelector(state => state.context.action)

    const [loadingButton, setLoadingButton] = useState(false)
    const [error, setError] = useState({
        signUp: {error: false, message: "E-mail já cadastrado!"}
    })

    const regex = {
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[,.;:/?~^´`'"!@#$%^&*])(?=.{8,})/,
    }

    const checkPassword = () => {
        return regex.password.test(form.password)
    }

    const doubleCheckPassowrd = () => {
        return form.confirmPassword === form.password
    }

    const validadeForms = () => {
        if(checkPassword() && doubleCheckPassowrd()) {
            return false
        }
        return true
    }

    const signUp = async () => {
        setLoadingButton(true)

        const body = {
            name: form.name,
            email: form.email,
            password: form.password,
            photo: false,
            beginner: true,
            biometric: false,
            biometricsAnswered: false,
        }

        const response = await auth.signUp(body)
        if(response.message === "SUCCESS") {
            setUser(response)

            dispatch(updateUser(response))

            dispatch(changeSolicitation({
                column: "uuidUser",
                value: response.user.uuid
            }))

            dispatch(changeSeriveProposal({
                column: "uuidUser",
                value: response.user.uuid
            }))

            if (contextAction === "saveConsumer") {
                dispatch(changeContext({
                    step: "maps",
                    action: "getSolicitation",
                    active: "solicitation"
                }))
            } else {
                dispatch(changeContext({
                    active: "payments",
                    step: "checkout",
                    action: "lead"
                }))
            }

            event.registerEvent({
                type: "completedRegistration",
                uuidLead: leadState.uuid,
                uuidUser: response.user.uuid,
                solicitation: solicitation
            })
        } else {
            setError({...error, signUp: {...error.signUp, error: true}})
        }

        setLoadingButton(false)
    }

    return (
        <>
            <div className='auth-content'>
                <span className='auth-subtitle'>Bem vintdo à Keeper</span>
                <h2>Cadastre-se</h2>
                <div className="auth-signup-user-content">
                    <PaperUser user={form} onClick={() => dispatch(changeStep("signUpStep1"))}/>
                </div>
                <div className="auth-signup-forms-content">
                    <InputPassword
                        error={!checkPassword() && form.password !== ''}
                        label="Cadastre sua senha"
                        value={form.password}
                        onChange={(event) => changeForm("password", event.target.value)}
                        helperText="A senha deve ter no mínimo 8 digitos, contendo uma letra maiúscula, uma letra minúscula, um número e um caractere especial."
                    />
                    <InputPassword
                        error={form.confirmPassword !== '' && !doubleCheckPassowrd()}
                        label="Confirme sua senha"
                        value={form.confirmPassword}
                        onChange={(event) => changeForm("confirmPassword", event.target.value)}
                        helperText="As duas senhas não são iguais."
                    />
                </div>
                <div className="auth-signup-terms-content">
                    <span>
                        Ao clicar em "Cadastrar agora" você concorda com todos o termos descritos em nosso contrato de Termos de Uso.
                    </span>
                    <button className="auth-link" style={{marginTop: 3, marginBottom: 0}}>Ler termos de uso do aplicativo</button>
                </div>
                <div className="auth-signup-button-content">
                    <Button 
                        loading={loadingButton}
                        disabled={validadeForms()}
                        label="Cadastrar agora" 
                        onClick={signUp}
                    />
                </div>
                <div className='auth-link-content'>
                    <span>Já possui uma conta?</span>
                    <button 
                        className='auth-link' 
                        onClick={() => dispatch(changeContext({
                            active: "auth",
                            step: "",
                            action: ""
                        }))}
                        style={{marginTop: 0, marginBottom: 0}}
                    >
                        Faça o login!
                    </button>
                </div>
            </div>
            <CustomAlert 
                show={error.signUp.error}
                message={error.signUp.message}
                onClose={() => setError({...error, signUp: {...error.signUp, error: false}})}
            />
        </>
    )
}

export default SignUpStep2