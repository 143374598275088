import { useState } from "react"
import { Modal } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { openBottomSheet } from "../../../actions/bottomSheets"
import { openModal } from "../../../actions/modal"

import './styles.css'
import Button from "../../Button"

const ConfirmLocationMOdal = ({
    onConfirm
}) => {
    const dispatch = useDispatch()

    const solicitation = useSelector(state => state.solicitation)
    const modalActive = useSelector(state => state.modal.confirmLocation)

    const [loadingButton, setLoadingButton] = useState(false)

    const handleClose = () => {
        dispatch(openModal({modal: "confirmLocation", value: false}))
    }

    const handleUpdateSolicitation = async () => {
        setLoadingButton(true)
        await onConfirm()
        setLoadingButton(false)
        handleClose()
    }

    const printStreet = (place) => {
        return `${place.street}, ${place.streetNumber}${place.district ? ", " + place.district : ""}`
    }

    const printState = (place) => {
        return `${place.city}, ${place.state}`
    }

    return (
        <Modal
            open={modalActive}
            onClose={handleClose}
        >
            <div className="modal-confirm-location-container">
                <div className="modal-confirm-location-title-content">
                    <h2>Confirma sua localização?</h2>
                    <div className='modal-confirm-location-formatted-addresss-result-content'>
                        <span className='modal-confirm-location-formatted-addresss'>{printStreet(solicitation.current_location)}</span>
                        <span className='modal-confirm-location-formatted-addresss'>{printState(solicitation.current_location)}</span>
                    </div>
                </div>
                <div>
                </div>
                <div className="modal-confirm-location-button-content">
                    <Button 
                        kind="secondary" 
                        label="Editar" 
                        onClick={handleClose}
                    />
                    <Button 
                        loading={loadingButton}
                        kind="primary" 
                        label="Continuar" 
                        onClick={handleUpdateSolicitation}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default ConfirmLocationMOdal
