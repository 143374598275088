import { useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"

import { changeStep } from "../../../actions/context"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { changeSolicitation } from "../../../actions/solicitations"
import { setLastServiceSelected } from "../../../actions/lead"

import * as event from '../../../services/event'

import Title from "../../../components/Title"

import Vehicle from "./Vehicle"
import Location from "./Location"
import Contact from "./Contact"

import './styles.css'

const Forms = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [lead, _, __] = useLocalStorage("lead", null)

    const user = useSelector(state => state.user)
    const leadState = useSelector(state => state.lead)
    const solicitation = useSelector(state => state.solicitation)
    const stepSelected = useSelector(state => state.context.action)

    const steps = {
        step3: { component: <Contact />, subtitle: "Passo 3 de 3 - Informações de contato", },
        step1: { component: <Vehicle />, subtitle: "Passo 1 de 3 - Dados do veículo", },
        step2: { component: <Location />, subtitle: "Passo 2 de 3 - Localização do veículo", },
    }

    const services = {
        "mecanico": 'Mecânico',
        "bateria": 'Socorro Bateria',
        "chaveiro": 'Chaveiro',
        "combustivel": 'Combustível',
        "pneu": 'Troca de Pneus',
        "reboque": "Reboque"
    }

    const goBack = () => {
        navigate("/")
        dispatch(changeStep("services"))
    }

    useEffect(() => {
        const setVh = () => {
          const vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
      
        setVh(); // Ajusta a altura no primeiro render
        window.addEventListener('resize', setVh);
      
        return () => window.removeEventListener('resize', setVh);
    }, [])

    useEffect(() => {
        console.log("opa")
        if (leadState.uuid !== "") {
            dispatch(changeSolicitation({
                column: "uuidLead", 
                value: leadState.uuid
            }))

            dispatch(changeSolicitation({
                column: "uuidUser", 
                value: user.type === "lead" ? null : user.uuid
            }))
        }

    }, [leadState.uuid])

    useEffect(() => {
        if (solicitation.service !== leadState.lastServiceSelected) {
            dispatch(setLastServiceSelected(solicitation.service))
        }
    }, [solicitation.service])

    useEffect(() => {
        if (leadState.uuid) {
            event.registerEvent({
                type: "selectService",
                service: solicitation.service,
                uuidLead: leadState.uuid
            })
        }
    }, [leadState.uuid])

    return (
        <div className="forms-container">
            <Title 
                goBack={goBack}
                label={`Solicitar ${services[solicitation.service]}`}
                subtitle={steps[stepSelected].subtitle}
            />
            {steps[stepSelected].component}
        </div>
    )
}
export default Forms