import { useDispatch } from 'react-redux';
import CustomAvatar from '../../Avatar';
import Button from '../../Button'

import './styles.css'
import { useState } from 'react';
import { changeContext } from '../../../actions/context';

const PaperServiceProposal = ({
    active,
    checkout,
    onSelected,
    serviceProposal,
    disabledApproved,
    handleRefuseServiceProposal,
    handleApproveServiceProposal
}) => {
    const dispatch = useDispatch()

    const [loadingButton, setLoadingButton] = useState(false)
    const [loadingRefuseButton, setLoadingRefuseButton] = useState(false)

    const handleLoadingButton = async () => {
        setLoadingButton(true)
        await handleApproveServiceProposal()
    }

    const handleLoadingRefuseButton = async () => {
        setLoadingRefuseButton(true)
        await handleRefuseServiceProposal()
        setLoadingRefuseButton(false)
    }
    return (
        <button 
            disabled={!onSelected} 
            className={`service-proposal-container ${active && "active"}`} 
            onClick={() => onSelected(serviceProposal.uuid)}
        >
            
            <div className='service-proposal-content'>
                <div>
                    {serviceProposal && serviceProposal.status === "approved" &&
                        <div className='service-proposal-status-content'>
                            <span>Aguardando prestador iniciar deslocamento</span>
                        </div>
                    }
                    <div className='service-proposal-avatar-content'>
                        <CustomAvatar 
                            size={36}
                            label={serviceProposal.provider.name}
                        />
                        <div className='service-proposal-avatar-labels'>
                            <span>{serviceProposal.provider.name}</span>
                            <label>{serviceProposal.company.name}</label>
                            {!checkout && <label>{`${Number(serviceProposal.distance).toFixed(2)} km`}</label>}
                        </div>
                    </div>
                </div>
                <div className='service-proposal-value'>
                    <label>Valor do serivço:</label>
                    <span className='service-proposal-value'>{Number(serviceProposal.price).toLocaleString(
                        "pt-BR", {style: "currency", currency: "BRL"}
                    )}</span>
                </div>
            </div>
            
            {serviceProposal && serviceProposal.status !== "approved" ? (
                !checkout &&
                    <>
                        <div className={`service-proposal-hr ${active && "active"}`}/>
                        <div className='service-proposal-button-content'>
                            <Button loading={loadingRefuseButton} kind="error" label="Recusar" style={{padding: 8}} onClick={handleLoadingRefuseButton}/>
                            <Button loading={loadingButton} disabled={disabledApproved} kind="" label="Contratar" style={{padding: 8}} onClick={handleLoadingButton}/>
                        </div>
                    </>
            ) : (
                <div>
                    <div className={`service-proposal-hr ${active && "active"}`}/>
                    <span style={{fontSize: 10, color: "#a2a2a2"}}>Aguarde só mais um instante enquanto atualizamos a solicitação com a localização em tempo real do prestador.</span>
                </div>
            )}
        </button>
    )
}

export default PaperServiceProposal;