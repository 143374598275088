import MecanicoIcon from '../../../assets/images/icon/m-mecanico.png'
import BateriaIcon from '../../../assets/images/icon/m-bateria.png'
import ChaveiroIcon from '../../../assets/images/icon/m-chaveiro.png'
import CombustivelIcon from '../../../assets/images/icon/m-combustivel.png'
import PneusIcon from '../../../assets/images/icon/m-pneus.png'
import ReboqueIcon from '../../../assets/images/icon/m-reboque.png'

import './styles.css'
import { Skeleton } from '@mui/material'

const PaperServices = ({
    label,
    icon,
    onClick,
    skeleton
}) => {
    const icons = {
        "Mecânico": {src: MecanicoIcon, bg: "#3B756B", color: "white"},
        "Combustível": {src: CombustivelIcon, bg: "#3579A0", color: "white"},
        "Socorro Bateria": {src: BateriaIcon, bg: "white", color: "#009CF5"},
        "Troca de Pneus": {src: PneusIcon, bg: "#009CF5", color: "white"},
        "Chaveiro": {src: ChaveiroIcon, bg: "#F5BE00", color: "white"},
        "Reboque": {src: ReboqueIcon, bg: "#03D1AE", color: "white"},
    }

    const servicesId = {
        'Mecânico': "mecanico",
        'Socorro Bateria': "bateria",
        'Chaveiro': "chaveiro",
        'Combustível': "combustivel",
        'Troca de Pneus': "pneu",
        'Reboque': 'reboque'
    }

    const serviceLabel = {
        'Mecânico': "Socorro Mecânico",
        'Socorro Bateria': "Recarga de Bateria",
        'Chaveiro': "Chaveiro",
        'Combustível': "Socorro Combustível",
        'Troca de Pneus': "Troca de Pneus",
        'Reboque': 'Reboque'
    }

    if (skeleton) {
        return (
            <div className='papaer-services-container'>
                <Skeleton variant="rectangular" width={48} height={18} />
                <Skeleton variant="rectangular" width={87} height={70} />
            </div>
        )
    } else {
        return (
            <button id={servicesId[icon]} className='papaer-services-container shadow' style={{background: icons[icon].bg}} onClick={onClick}>
                <span className='papaer-services-label' style={{color: icons[icon].color}}>{serviceLabel[label]}</span>
                <img src={icons[icon].src} alt={icon}/>
            </button>
        )
    }

}

export default PaperServices;