import axios from 'axios';

const googleMapsApi = axios.create({
    baseURL: process.env.REACT_APP_GOOGLE_MAPS_URL
})

export const getLocations = async (address) => {
    try {
        const response = await googleMapsApi.get('/geocode/json', {
            params: {
                address: address,
                key: process.env.REACT_APP_GOOGLE_API_KEY
            }
        })
        
        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("getLocations", error)
    }
}