import { forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import { InputMask } from '@react-input/mask';

import './styles.css'

const CellPhoneMask = forwardRef((props, forwardedRef) => {
    return <InputMask ref={forwardedRef} mask="(__) _____-____" replacement={{ _: /\d/ }}  {...props} />;
});

const CredCardMask = forwardRef((props, forwardedRef) => {
    return <InputMask ref={forwardedRef} mask="____ ____ ____ ____" replacement={{ _: /\d/ }}  {...props} />;
});

const ExpCardMask = forwardRef((props, forwardedRef) => {
    return <InputMask ref={forwardedRef} mask="__/__" replacement={{ _: /\d/ }}  {...props} />;
});

const DocumentPFMask = forwardRef((props, forwardedRef) => {
    return <InputMask ref={forwardedRef} mask="___.___.___-__" replacement={{ _: /\d/ }}  {...props} />;
});

const DocumentPJMask = forwardRef((props, forwardedRef) => {
    return <InputMask ref={forwardedRef} mask="__.___.___/____-__" replacement={{ _: /\d/ }}  {...props} />;
});

const CEPMask = forwardRef((props, forwardedRef) => {
    return <InputMask ref={forwardedRef} mask="__.___-___" replacement={{ _: /\d/ }}  {...props} />;
});

const InputText = ({
    label,
    rows,
    multiline,
    value,
    onChange,
    onBlur,
    type,
    error,
    helperText,
    style,
    mask,
    maxLength,
    placeholder
}) => {

    const masks = {
        cellphone: CellPhoneMask,
        card: CredCardMask,
        expcard: ExpCardMask,
        documentPF: DocumentPFMask,
        documentPJ: DocumentPJMask,
        cep: CEPMask
    }

    return (
        <div style={style}>
            <TextField
                className='input-text-field-container' 
                placeholder={placeholder}
                error={error}
                type={type}
                rows={rows}
                size="small"
                label={label}
                variant="outlined" 
                multiline={multiline}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputProps={maxLength && {
                    maxLength: maxLength
                }}
                InputProps={mask && {
                    inputComponent: masks[mask]
                }}
            />
            {error && <span style={{fontSize: 10, color: "#d32f2f", marginLeft: 6}}>{helperText}</span>}
        </div>
    )
}

export default InputText;