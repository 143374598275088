import  {
    useMap,
    AdvancedMarker
} from '@vis.gl/react-google-maps'

import PointCarIcon from '../../assets/images/icon/pointer-car.svg';
import PointTruckIcon from '../../assets/images/icon/pointer-truck.svg';
import PointGarageIcon from '../../assets/images/icon/pointer-garage.svg';
import PointMarioIcon from '../../assets/images/icon/pointer-mario.svg';

export const Marker = ({
    position,
    icon
}) => {
    const map = useMap()

    const icons = {
        carro: PointCarIcon,
        reboque: PointTruckIcon,
        oficina: PointGarageIcon,
        mecanico: PointMarioIcon
    }

    return (
        <AdvancedMarker position={position}>
            <img alt="marker" src={icons[icon]}/>
        </AdvancedMarker>
    )
}