import { IconButton } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import CustomAvatar from "../../Avatar"

import './styles.css'

const PaperUser = ({
    user,
    onClick,
    trash
}) => {
    return (
        <div className="paper-user-container">
            <div className="paper-user-content">
                <CustomAvatar label={user.name} size={38}/>
                <div className="paper-user-contats-content">
                    <span>{user.name}</span>
                    <label>{user.email}</label>
                </div>
            </div>
            <IconButton
                onClick={onClick}
            >
                {trash ? <DeleteIcon /> : <EditIcon style={{color: "#009CF5"}}/>}
            </IconButton>
        </div>
    )
}

export default PaperUser;