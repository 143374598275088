import { useState } from "react"
import { jwtDecode } from "jwt-decode";
import { useDispatch } from 'react-redux'
import { GoogleLogin } from '@react-oauth/google';
import { updateUser } from "../../../../actions/user";

import * as auth from '../../../../services/auth';

import Button from "../../../../components/Button"
import InputText from "../../../../components/Input/Text"
import InputPassword from "../../../../components/Input/Password"
import CustomAlert from '../../../../components/Alert';
import { changeSolicitation } from "../../../../actions/solicitations";
import { changeContext, changeStep } from "../../../../actions/context";
import { changeSeriveProposal } from "../../../../actions/serviceProposals";

const SignInWithoutUser = ({
    form,
    setUser,
    changeForm
}) => {
    const dispatch = useDispatch()

    const [loadingButton, setLoadingButton] = useState(false)
    const [error, setError] = useState({
        signin: {error: false, message: "E-mail ou senha inválidos."}
    })

    const signIn = async () => {
        setLoadingButton(true)
        const response = await auth.signIn(form)

        if(response.message === "SUCCESS") {
            setUser(response)

            dispatch(updateUser(response))

            dispatch(changeSolicitation({
                column: "uuidUser",
                value: response.user.uuid
            }))

            dispatch(changeSeriveProposal({
                column: "uuidUser",
                value: response.user.uuid
            }))

            dispatch(changeContext({
                active: "payments",
                step: "checkout",
                action: "user"
            }))
        } else {
            setError({...error, signin: {...error.signin, error: true}})
        }

        setLoadingButton(false)
    }

    const handleGoToRegister = () => {
        dispatch(changeStep("signUpStep1"))
    }

    const handleGoogleAuth = async (credentialResponse) => {
        const user = jwtDecode(credentialResponse.credential)
        const response = await auth.signInGoogle({
            name: user.name,
            email: user.email,
            photo: user.picture,
            provider: "googleOAuth"
        })

        if(response.message === "SUCCESS") {
            setUser(response)

            dispatch(updateUser(response))

            dispatch(changeSolicitation({
                column: "uuidUser",
                value: response.user.uuid
            }))

            dispatch(changeSeriveProposal({
                column: "uuidUser",
                value: response.user.uuid
            }))

            dispatch(changeContext({
                active: "payments",
                step: "checkout",
                action: "user"
            }))
        }
    }

    return (
        <>
            <div className='auth-content'>
                <span className='auth-subtitle'>Bem vintdo à Keeper</span>
                <h2>Entre em sua conta</h2>
                <div className='auth-forms-content'>
                    <InputText 
                        type="email"
                        label="Seu e-mail"
                        value={form.email}
                        onChange={(event) => changeForm("email", event.target.value)}
                        style={{marginBottom: 12}}
                    />
                    <InputPassword 
                        label="Seu senha"
                        value={form.password}
                        onChange={(event) => changeForm("password", event.target.value)}
                        style={{marginBottom: 12}}
                    />
                    <Button 
                        loading={loadingButton}
                        label="Entrar na minha conta"
                        onClick={signIn}
                    />
                    <div className="auth-singin-provider-content">
                        <GoogleLogin
                            useOneTap
                            context="signup"
                            onSuccess={credentialResponse => handleGoogleAuth(credentialResponse)}
                            onError={() => console.log('Login Failed')}
                            locale="pt_BR"
                        />
                    </div>
                </div>
                <button className='auth-link' onClick={() => dispatch(changeStep("passwordStep1"))}>Esqueceu a senha?</button>
                <div className='auth-button-content'>
                    <span>Seu primeiro acesso?</span>
                    <Button 
                        kind="secondary"
                        label="Cadastre-se"
                        onClick={handleGoToRegister}
                    />
                </div>
            </div>
            <CustomAlert 
                show={error.signin.error}
                message={error.signin.message}
                onClose={() => setError({...error, signin: {...error.signin, error: false}})}
            />
        </>
    )
}

export default SignInWithoutUser