import { createSlice } from '@reduxjs/toolkit'

export const bottomSheetSlice = createSlice({
    name: "bottomSheets",
    initialState: {
        solicitation: true
    },
    reducers: {
        openBottomSheet: (state, action) => {
            const { payload } = action

            state[payload.bottomSheet] =  payload.value
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    openBottomSheet
} = bottomSheetSlice.actions

export default bottomSheetSlice.reducer