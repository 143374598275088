import { createSlice } from '@reduxjs/toolkit'

export const messagesSlice = createSlice({
    name: "messages",
    initialState: {
        list: [],
        provider: {}
    },
    reducers: {
        insertNewMessage: (state, action) => {
            if (Array.isArray(action.payload)) state.list = action.payload
            else state.list.push(action.payload)
        },
        loadMessages: (state, action) => {
            state.list = action.payload
        },
        saveProvider: (state, action) => {
            state.provider = action.payload
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    insertNewMessage,
    loadMessages,
    saveProvider
} = messagesSlice.actions

export default messagesSlice.reducer