import { useState } from "react"

import * as auth from '../../../../services/auth';

import Button from "../../../../components/Button"
import InputPassword from "../../../../components/Input/Password"
import { useDispatch } from "react-redux";
import { changeContext } from "../../../../actions/context";

const PasswordStep3 = ({
    form,
    changeForm
}) => {
    const regex = {
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[,.;:/?~^´`'"!@#$%^&*])(?=.{8,})/,
    }

    const dispatch = useDispatch()
    const [loadingButton, setLoadingButton] = useState(false)

    const checkPassword = () => {
        return regex.password.test(form.password)
    }

    const doubleCheckPassowrd = () => {
        return form.confirmPassword === form.password
    }

    const validadeForms = () => {
        if(checkPassword() && doubleCheckPassowrd()) {
            return false
        }
        return true
    }

    const resetPassword = async () => {
        setLoadingButton(true)
        
        const body = {
            uuid: form.uuid,
            email: form.email,
            password: form.password
        }
        const response = await auth.updateUser(body)
        if(response.message === "SUCCESS") dispatch(changeContext({
            active: "auth",
            action: "",
            step: "",
        }))

        setLoadingButton(false)
    }

    return (
        <div className='auth-content'>
            <span className='auth-subtitle'>Bem vintdo à Keeper</span>
            <h2>Esqueceu a senha?</h2>
            <div className='auth-forms-content'>
                <InputPassword
                    label="Cadastre sua senha"
                    value={form.password}
                    error={!checkPassword() && form.password !== ''}
                    onChange={(event) => changeForm("password", event.target.value)}
                    helperText="A senha deve ter no mínimo 8 digitos, contendo uma letra maiúscula, uma letra minúscula, um número e um caractere especial."
                    style={{marginBottom: 12}}
                />
                <InputPassword
                    label="Confirme sua senha"
                    value={form.confirmPassword}
                    error={form.confirmPassword !== '' && !doubleCheckPassowrd()}
                    onChange={(event) => changeForm("confirmPassword", event.target.value)}
                    helperText="As duas senhas não são iguais."
                    style={{marginBottom: 12}}
                />
                <Button 
                    loading={loadingButton}
                    disabled={validadeForms()}
                    onClick={resetPassword}
                    label="Cadastrar nova senha"
                />
            </div>
            <div className='auth-link-content'>
                <span>Já possui uma conta?</span>
                <button 
                    className='auth-link' 
                    onClick={() => dispatch(changeContext({
                        active: "auth",
                        step: ""
                    }))}
                    style={{marginTop: 0, marginBottom: 0}}
                >
                    Faça o login!
                </button>
            </div>
        </div>
    )
}

export default PasswordStep3