import React, { useEffect, useState } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useDispatch, useSelector } from "react-redux";

import Authentication from "./Authentication";
import Solicitation from "./Solicitation";
import Payment from "./Payment";

import './styles.css'
import { setUuidLead } from "../actions/lead";
import { updateUser } from "../actions/user";
const App = () => {
    const dispatch = useDispatch()

    const [user, setUser, getUser] = useLocalStorage("user", null);
    const [lead, setLead, getLead] = useLocalStorage("lead", null);

    const contextControler = useSelector(state => state.context)

    useEffect(() => {
        const leadStoraged = getLead("lead")
        const userStoraged = getUser("user")

        if (leadStoraged) dispatch(setUuidLead(leadStoraged))
        if (userStoraged) dispatch(updateUser(userStoraged))
    }, [])

    const context = {
        solicitation: <Solicitation />,
        auth: <Authentication />,
        payments: <Payment />,
    }

    return context[contextControler.active]
}

export default App;