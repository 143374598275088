import { useEffect, useState } from "react";
import { getServiceProposalsBySolicitation } from "../../../services/solicitations";
import { useDispatch } from "react-redux";
import { changeContext, changeStep } from "../../../actions/context";
import { changeSolicitation, recreateSolicitation } from "../../../actions/solicitations";

import CustomAvatar from "../../Avatar";

import SmallProviderIcon from '../../../assets/images/icon/small-provider.svg';
import MoneyIcon from '../../../assets/images/icon/small-money.svg';

import './styles.css'
import { insertServiceProposals, saveSelectedServiceProposals } from "../../../actions/serviceProposals";

const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

const HistServices = ({
    solicitation
}) => {
    const dispatch = useDispatch()

    const [serviceProposals, setServiceProposals] = useState([])
    const [serviceProposalApproved, setServiceProposalsApproved] = useState()

    const titles = {
        reboque: "Reboque",
        bateria: "Carga de Bateria",
        chaveiro: "Chaveiro",
        combustivel: "Combustível",
        mecanico: "Mecânico",
        pneu: "Troca de Pneus"
    }

    const status = {
        canceled: "Cancelado",
        active: "Em andamento",
        new: "Em andamento",
        finish: "Finalizado",
    }

    const formatDate = (date) => {
        const newDate = new Date(date)
        const newDateUtc3 = new Date(newDate.setHours(newDate.getHours() -3))
        
        return newDateUtc3.toLocaleDateString("pt-br") + ' ' + newDateUtc3.toLocaleTimeString("pt-br")
    }

    const handleOpenSolicitation = () => {
        dispatch(changeSolicitation({
            column: "uuid",
            value: solicitation.uuid,
        }))

        dispatch(insertServiceProposals(serviceProposals))

        if (serviceProposalApproved) dispatch(saveSelectedServiceProposals({
            uuid: serviceProposalApproved.uuid
        }))

        dispatch(changeContext({
            step: "maps",
            action: "getSolicitation",
            active: "solicitation"
        }))
    }

    useEffect(() => {
        (async() => {
            const response = await getServiceProposalsBySolicitation(solicitation.uuid)
            if (response) {
                const validate = response.filter(item => item.status === "approved")[0]

                setServiceProposalsApproved(validate)
                setServiceProposals(response)
            }
        })()
    }, [solicitation])

    return (
        <button className="hist-service-container shadow" onClick={handleOpenSolicitation}>
            <div className="hist-service-title-content">
                <label>{titles[solicitation.service]}</label>
                {solicitation.status === "new" ? (
                    <div className={"hist-service-proposals"}>
                        <label>Propostas:</label>
                        <span>{serviceProposals.length}</span>
                    </div>
                ) : solicitation.paymentStatus === "pending" ? "" : (
                    <span className={`hist-service-status ${solicitation.status}`}>{status[solicitation.status]}</span>
                )}
            </div>
            {solicitation.status !== "new" ? (
                <div className="hist-service-content">
                    <div>
                        <div className="hist-service-subtitle-content">
                            <img src={SmallProviderIcon} alt="provider"/>
                            <span>Prestador</span>
                        </div>
                        {solicitation && solicitation.provider &&
                            <div className="hist-service-provider-content">
                                <CustomAvatar label={solicitation.provider.name} size={32}/>
                                <div className="hist-service-provider-info">
                                    <label>{solicitation.provider.name}</label>
                                    {solicitation.company &&
                                        <span>{solicitation.company.name}</span>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                        <div className="hist-service-subtitle-content">
                            <img src={MoneyIcon} alt="money"/>
                            <span>Valor total</span>
                        </div>
                        <div className="hist-service-value-info">
                            <label>{Number(solicitation.price).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</label>
                            <span>{formatDate("2024-02-19T12:06:34.613156")}</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="hist-service-describe">
                    <span>{(Object.keys(solicitation).includes("company") && Object.keys(solicitation).includes("provider")) ? (
                        "Estamos aguardando o prestador iniciar o deslocamento."
                    ) : serviceProposals.length > 0 ? (
                        "Estamos aguardando a sua aprovação para enviarmos o prestador até você."
                    ) : solicitation.paymentStatus === "pending" ? (
                        "Estamos aguardando a confirmação do pagamento."
                    ) : (
                        "Estamos procurando por um prestador para atender sua demanda."
                    )}</span>
                </div>
            )}
            
        </button>
    )
}
export default HistServices;