import { createSlice } from '@reduxjs/toolkit'

export const servicesSlice = createSlice({
    name: 'services',
    initialState: {
        auto: [],
        loading: false
    },
    reducers: {
        loadServices: (state, action) => {
            const { payload } = action
            
            state.auto = payload.filter(item => item.type === "auto")
        },
        loadingServices: (state, action) => {
            state.loading = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { loadServices, loadingServices } = servicesSlice.actions

export default servicesSlice.reducer