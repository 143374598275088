import { useState } from "react"

import api from "../../../../services/api"

import CustomAlert from "../../../../components/Alert"
import Button from "../../../../components/Button"
import InputText from "../../../../components/Input/Text"
import { useDispatch } from "react-redux"
import { changeStep } from "../../../../actions/context"

const PasswordStep2 = ({
    form,
    changeForm
}) => {
    const dispatch = useDispatch()
    const [loadingButton, setLoadingButton] = useState(false)
    const [error, setError] = useState({
        token: {error: false, message: "Token inválido."}
    })

    const validateToken = async () => {
        setLoadingButton(true)

        const params = {
            params: {
                email: form.email,
                code: form.token
            }
        }
        
        try {
            const response = await api.get(`/resetPassword/validateCode`, params)
            if(response.status === 200) dispatch(changeStep("passwordStep3"))
            else setError({...error, token: {...error.token, error: true}})
        } catch (e) {
            setError({...error, token: {...error.token, error: true}})
        }

        setLoadingButton(false)
    }

    return (
        <>
            <div className='auth-content'>
                <span className='auth-subtitle'>Bem vintdo à Keeper</span>
                <h2>Esqueceu a senha?</h2>
                <div className='auth-forms-content' style={{gridTemplateRows: "1fr 1fr"}}>
                    <InputText 
                        type="number"
                        label="Digite o token"
                        value={form.token}
                        onChange={(event) => changeForm("token", event.target.value)}
                        style={{marginBottom: 12}}
                    />
                    <Button 
                        loading={loadingButton}
                        onClick={validateToken}
                        label="Validar token"
                    />
                </div>
                <div className='auth-link-content'>
                    <span>Já possui uma conta?</span>
                    <button 
                        className='auth-link' 
                        style={{marginTop: 0, marginBottom: 0}}
                        onClick={() => dispatch({
                            active: "auth",
                            step: ""
                        })}
                    >
                        Faça o login!
                    </button>
                </div>
            </div>
            <CustomAlert
                show={error.token.error}
                message={error.token.message}
                onClose={() => setError({...error, token: {...error.token, error: false}})}
            />
        </>
    )
}

export default PasswordStep2