import { Modal } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../../actions/modal"

import DepressionVector from '../../../assets/images/vector/depression.svg'

import './styles.css'
import CustomSelect from "../../Input/Select"
import { useState } from "react"
import { openBottomSheet } from "../../../actions/bottomSheets"
import Button from "../../Button"
import { cancelSolicitation } from "../../../services/solicitations"

const DepressionModal = ({
    open,
    label,
    activeReasonForCancelation
}) => {
    const dispatch = useDispatch()

    const user = useSelector(state => state.user)
    const lead = useSelector(state => state.lead)
    const solicitation = useSelector(state => state.solicitation)

    const [loadingButton, setLoadingButton] = useState(false)
    const [reasonForCancellation, setReasonForCancelation] = useState("")

    const handleClose = () => {
        dispatch(openBottomSheet({bottomSheet: "solicitation", value: true}))
        dispatch(openModal({modal: "depression", value: false}))
    }

    const handleCancelSolicitation = async () => {
        setLoadingButton(true)

        const uuidUser = user.type === "lead" ? lead.uuid : user.uuid
        await cancelSolicitation(uuidUser, solicitation, reasonForCancellation)

        dispatch(openModal({modal: "depression", value: false}))
        dispatch(openBottomSheet({bottomSheet: "solicitation", value: true}))

        setLoadingButton(false)
    }

    const reasonForCancellationOptions = [
        { id: 'Valor alto após ajuste', label: 'Valor alto após ajuste' },
        { id: 'O prestador não chegou', label: 'O prestador não chegou' },
        { id: 'Não consegui encontrar prestador', label: 'Não consegui encontrar prestador' },
        { id: 'O tempo de espera foi muito longo', label: 'O tempo de espera foi muito longo' },
        { id: 'O prestador não está aproximando', label: 'O prestador não está aproximando' },
        { id: 'Consegui resolver meu problema', label: 'Consegui resolver meu problema' },
        { id: 'Outros', label: 'Outros' },
    ]

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <div className="modal-depression-container">
                <div className="modal-depression-title-content">
                    <h2 className="modal-depression-title">Oooh... que pena!</h2>
                    <span className="modal-depression-subtitle">{label ? label : "Tem certeza que deseja cancela sua solicitação? \n\nCaso o prestador tenha aprovado a sua solicitação, a cobrança do deslocamento total do prestador será efetuda."}</span>
                </div>
                <div className="modal-depression-vector-content">
                    <img src={DepressionVector} alt="depression" className="modal-depression-vector"/>
                </div>
                {activeReasonForCancelation &&
                    <div className="modal-depression-select-content">
                        <CustomSelect 
                            label="Qual o motivo do cancelamento?"
                            options={reasonForCancellationOptions}
                            value={reasonForCancellation}
                            onChange={(event) => setReasonForCancelation(event.target.value)}
                        />
                    </div>
                }
                <div className="modal-depression-button-content">
                    <Button kind="secondary" label="Manter serviço" onClick={handleClose}/>
                    <Button 
                        disabled={reasonForCancellation === ""} 
                        loading={loadingButton} 
                        kind="error" 
                        label="Cancelar serviço" 
                        onClick={handleCancelSolicitation}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default DepressionModal;