import { useState } from "react"
import { Modal } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { openBottomSheet } from "../../../actions/bottomSheets"
import { openModal } from "../../../actions/modal"

import StarFilledIcon from '../../../assets/images/icon/star-filled.svg';
import StarOutlinedIcon from '../../../assets/images/icon/star-outlined.svg';

import './styles.css'
import Button from "../../Button"
import { changeSolicitation } from "../../../actions/solicitations"
import { finishSolicitation } from "../../../services/solicitations"

const FinishServiceModal = () => {
    const dispatch = useDispatch()

    const solicitation = useSelector(state => state.solicitation)
    const modalActive = useSelector(state => state.modal.finishService)

    const [loadingButton, setLoadingButton] = useState(false)
    const [rateSelected, setRate] = useState(0)

    const rates = [1, 2, 3, 4, 5]

    const handleClose = () => {
        dispatch(openBottomSheet({bottomSheet: "solicitation", value: true}))
        dispatch(openModal({modal: "finishService", value: false}))
    }

    const handleUpdateSolicitation = async () => {
        setLoadingButton(true)
        await finishSolicitation(solicitation)
        handleClose()
        setLoadingButton(false)
    }

    return (
        <Modal
            open={modalActive}
            onClose={handleClose}
        >
            <div className="modal-finish-service-container">
                <div className="modal-finish-service-title-content">
                    <h2>Finalizar o solicitação</h2>
                    <span>E ai!!! Sua solicitação foi bem atendida? Por favor nos conte como foi a sua experiência.</span>
                </div>
                <div className="modal-finish-service-star-grid">
                    {rates.map((rate) => {
                        return (
                            <button 
                                key={rate} 
                                className="modal-finish-service-star" 
                                onClick={() => dispatch(changeSolicitation({
                                    column: "rate",
                                    value: rate
                                }))} 
                            >
                                <img 
                                    alt={`rate-${rate}`}
                                    src={rate <= solicitation.rate ? StarFilledIcon : StarOutlinedIcon} 
                                />
                            </button>
                        )
                    })}
                </div>
                <div className="modal-finish-service-button-content">
                    <Button 
                        loading={loadingButton}
                        kind="primary" 
                        label="Avaliar atendimento" 
                        onClick={handleUpdateSolicitation}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default FinishServiceModal
