import { createSlice } from '@reduxjs/toolkit'

export const contextSilce = createSlice({
    name: "context",
    initialState: {
        active: "solicitation",
        step: "services"
    },
    reducers: {
        changeContext: (state, action) => {
            const { payload } = action
            
            state.step = payload.step
            state.action = payload.action
            state.active =  payload.active
        },

        changeStep: (state, action) => {
            state.step =  action.payload
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    changeContext,
    changeStep
} = contextSilce.actions

export default contextSilce.reducer