import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import './styles.css'

const Title = ({
    goBack,
    label,
    subtitle
}) => {
    return (
        <div className="title-content">
            {goBack && 
                <IconButton 
                    onClick={goBack}
                    aria-label="goBack" 
                    style={{padding: 0, marginRight: 12}}
                >
                    <ArrowBackIcon style={{color: '#009CF5'}}/>
                </IconButton>
            }
            <div>
                <h1 className="title" >{label}</h1>
                {subtitle && <span className='subtitle'>{subtitle}</span>}
            </div>
        </div>
    )
}

export default Title;