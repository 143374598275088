import { useState } from 'react'

import Button from "../../../../components/Button"
import InputText from "../../../../components/Input/Text"
import { useDispatch } from 'react-redux'
import { changeContext, changeStep } from '../../../../actions/context'

const SignUpStep1 = ({
    form,
    changeForm
}) => {
    const regex = {
        name: /^[a-zA-Z\u00C0-\u017F´]{4,}(?: [a-zA-Z\u00C0-\u017F´]+){0,}$/,
        email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    }

    const dispatch = useDispatch()
    const [formsError, setErro] = useState({
        name: {error: false, message: "Nome deve conter pelo menos 4 caracteres."},
        email: {error: false, message: "E-mail inválido."}
    })

    const checkName = () => {
        const text = form.name.replace(/^\s+|\s+$/gm,'');

        if (text !== '' && regex.name.test(text)) {
            setErro({ ...formsError, name: { ...formsError.name, error: false}})
            return true
        }
        setErro({ ...formsError, name: { ...formsError.name, error: true}})
        return false
    }

    const checkEmail = () => {
        const text = form.email.replace(/^\s+|\s+$/gm,'');

        if (text !== '' && regex.email.test(text)) {
            setErro({ ...formsError, email: { ...formsError.email, error: false}})
            return true
        }
        setErro({ ...formsError, email: { ...formsError.email, error: true}})
        return false
    }

    const validadeForms = () => {
        const name = form.name.replace(/^\s+|\s+$/gm,'')
        const email = form.email.replace(/^\s+|\s+$/gm,'');

        if (name === '' || email === '')
            return true

        else if (formsError.name.error || formsError.email.error)
            return true

        return false
    }

    return (
        <div className='auth-content'>
            <span className='auth-subtitle'>Bem vintdo à Keeper</span>
            <h2>Cadastre-se</h2>
            <div className='auth-forms-content'>
                <InputText 
                    label="Seu nome"
                    value={form.name}
                    onBlur={() => checkName()}
                    error={formsError.name.error}
                    helperText={formsError.name.message}
                    onChange={(event) => changeForm("name", event.target.value)}
                    style={{marginBottom: 12}}
                />
                <InputText 
                    type="email"
                    label="Seu e-mail"
                    value={form.email}
                    onBlur={() => checkEmail()}
                    error={formsError.email.error}
                    helperText={formsError.email.message}
                    onChange={(event) => changeForm("email", event.target.value)}
                    style={{marginBottom: 12}}
                />
                <Button 
                    disabled={validadeForms()}
                    onClick={() => dispatch(changeStep("signUpStep2"))}
                    label="Próximo passo"
                />
            </div>
            <div className='auth-link-content'>
                <span>Já possui uma conta?</span>
                <button 
                    className='auth-link' 
                    onClick={() => dispatch(changeContext({
                        active: "auth",
                        step: "",
                        action: ""
                    }))}
                    style={{marginTop: 0, marginBottom: 0}}
                >
                    Faça o login!
                </button>
            </div>
        </div>
    )
}

export default SignUpStep1