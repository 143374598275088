import { useRef } from 'react'

import { BottomSheet } from 'react-spring-bottom-sheet'

import PaperServiceProposal from '../../Paper/ServiceProposals'

import 'react-spring-bottom-sheet/dist/style.css'
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { saveSelectedServiceProposals } from '../../../actions/serviceProposals'
import { refuseServiceProposal } from '../../../services/solicitations'
import { openBottomSheet } from '../../../actions/bottomSheets'
import { openModal } from '../../../actions/modal'
import Button from '../../Button'
import SmallCashIcon from '../../../assets/images/icon/s-cash.svg';
import api from '../../../services/api'
import { useState } from 'react'

const RetrySolicitationBottomSheet = ({
    open,
    onRetrySolicitation
}) => {
    const sheetRef = useRef()

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)

    const handleOnRetrySolicitation = async () => {
        setLoading(true)
        await onRetrySolicitation()
        setLoading(false)
    }

    const handleOpenModal = () => {
        dispatch(openBottomSheet({bottomSheet: "solicitation", value: false}))
        dispatch(openModal({modal: "depression", value: true}))
    }

    return (
        <BottomSheet 
            className="bottom-sheet-container"
            ref={sheetRef}
            open={open}
            defaultSnap={({ maxHeight }) => maxHeight / 4}
            snapPoints={({ maxHeight, minHeight }) => [
                maxHeight - maxHeight / 10,
                maxHeight / 4,
                maxHeight * 0.6,
                minHeight
            ]}
        >
            <div className='bottom-sheet-content'>
                <span className='bottom-sheet-subtitle'> Não conseguimos encontrar um prestador </span>
                <div className='bottom-sheet-content-divider'/>
                <div className='bottom-sheet-button-content'>
                    <Button 
                        loading={loading}
                        kind="primary" 
                        label="Deseja procurar novamente por um prestador?"
                        onClick={handleOnRetrySolicitation}
                        style={{
                            marginBottom: 12
                        }}
                    />
                    <Button 
                        kind="secondary" 
                        label="Cancelar serviço"
                        onClick={handleOpenModal}
                    />
                </div>
            </div>
        </BottomSheet>
    )
}

export default RetrySolicitationBottomSheet;