import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { openBottomSheet } from "../../../actions/bottomSheets"
import { openModal } from "../../../actions/modal"
import { BottomSheet } from "react-spring-bottom-sheet"

import Button from "../../Button"
import PaperProvider from "../../Paper/Provider"

import StarFilledIcon from '../../../assets/images/icon/star-filled.svg';
import StarOutlinedIcon from '../../../assets/images/icon/star-outlined.svg';
import SmallCashIcon from '../../../assets/images/icon/s-cash.svg';
import SmallPointIcon from '../../../assets/images/icon/small-point.svg';

import './styles.css'

const FinishSolicitationBottomSheet = ({
    open,
    provider
}) => {
    const dispatch = useDispatch()
    const sheetRef = useRef()

    const solicitation = useSelector(state => state.solicitation)

    const rates = [1, 2, 3, 4, 5]

    const handleOpenModal = (modal) => {
        dispatch(openBottomSheet({bottomSheet: "solicitation", value: false}))
        dispatch(openModal({modal: modal, value: true}))
    }

    const handleReloadPage = () => {
        window.location.href = '/'
    }

    return (
        <BottomSheet 
            className="bottom-sheet-container"
            ref={sheetRef}
            open={open}
            defaultSnap={({ maxHeight }) => maxHeight / 4}
            snapPoints={({ maxHeight, minHeight }) => [
                maxHeight - maxHeight / 10,
                maxHeight / 4,
                maxHeight * 0.6,
                minHeight
            ]}
        >
            <div className='bottom-sheet-content'>
                <div className='bottom-sheet-title-content'>
                    <div className='bottom-sheet-provider-content'>
                        {solicitation.stage === "start_service" ? (
                            <>
                                <PaperProvider 
                                    provider={{
                                        name: "João Pedro",
                                        company: {
                                            name: "Keeper"
                                        }
                                    }} 
                                    style={{
                                        marginBottom: 6
                                    }}
                                />
                                <span className="bottom-sheet-subtitle">O prestador iniciou o atendimento. Lembre-se de finalizar a solicitação no termino do atendimento.</span>
                            </>
                        ) : (
                            <div className="bottom-sheet-finish-service-content">
                                <label>Serviço finalizado com sucesso.</label>
                                <span>Agradecemos a preferencia e esperamos ter conseguido solucionar o seu problema da melhor forma possivel.</span>
                                <div className="bottom-sheet-finish-service-rate-content">
                                    {rates.map((rate) => {
                                        return (
                                            <img 
                                                key={rate}
                                                alt={`rate-${rate}`}
                                                src={rate <= solicitation.rate ? StarFilledIcon : StarOutlinedIcon} 
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='bottom-sheet-content-divider'/>
                {solicitation.type !== "purchase" &&
                    <div className='bottom-sheet-price-content'>
                        <div className='bottom-sheet-price-title'>
                            <img src={SmallCashIcon} alt="cash"/>
                            <span>{`Valor total à ${solicitation.stage === "start_service" ? "pagar" : "pago"} pelo serviço`}</span>
                        </div>
                        {solicitation.stage === "start_service" && <span>A cobrança somente será executado após a finalização do serviço</span>}
                        <label>{Number(solicitation.price).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</label>
                    </div>
                }
                <div className='bottom-sheet-button-content'>
                    {solicitation.stage === 'start_service' ? (
                        <>
                            <Button 
                                kind="primary" 
                                label="Finalizar serviço"
                                onClick={() => handleOpenModal("finishService")}
                                style={{
                                    marginBottom: 12
                                }}
                            />
                            <Button 
                                kind="secondary" 
                                label="Cancelar serviço"
                                onClick={() => handleOpenModal("depression")}
                            />
                        </>
                    ) : (
                        <Button 
                            kind="primary" 
                            label="Voltar ao menu inicial"
                            onClick={handleReloadPage}
                        />
                    )}
                </div>
            </div>
        </BottomSheet>
    )
}

export default FinishSolicitationBottomSheet