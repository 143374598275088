import { useDispatch, useSelector } from "react-redux"
import InputSelect from "../../../../../components/Input/Select"
import { changeTiresConfig } from "../../../../../actions/solicitations"

const FormsPneus = ({
    errorTireCondition,
    errorHasTools,
    errorHasSteppe,
    errorSteppeIsFull
}) => {
    const solicitation = useSelector(state => state.solicitation)
    const dispatch = useDispatch()

    const tireConditionsOptions = [
        {id: "furado", label: "Furado"},
        {id: "rasgado", label: "Rasgado"},
        {id: "estourado", label: "Estourado"}
    ]

    const hasSteppeOptions = [
        {id: "sim", label: "Sim"},
        {id: "não", label: "Não"},
    ]

    return (
        <>
            <div className='vehicle-forms-input-item'>
                <div className='vehicle-forms-title-input' style={{marginBottom: 8}}>
                    <span>Como está a situação do pneu?</span>
                </div>
                <InputSelect 
                    label="Qual a situação do pneu?"
                    options={tireConditionsOptions}
                    style={{marginBottom: 6}}
                    value={solicitation.tiresConfig.tire_conditions}
                    error={errorTireCondition}
                    helperText="A resposta não pode ser vazia."
                    onChange={(event) => dispatch(changeTiresConfig({
                        column: "tire_conditions", 
                        value: event.target.value
                    }))}
                />
                <InputSelect 
                    label="Você possui ferramentas?"
                    options={hasSteppeOptions}
                    style={{marginBottom: 6, marginTop: 6}}
                    value={solicitation.tiresConfig.has_tools}
                    error={errorHasTools}
                    helperText="A resposta não pode ser vazia."
                    onChange={(event) => dispatch(changeTiresConfig({
                        column: "has_tools", 
                        value: event.target.value
                    }))}
                />
                <InputSelect 
                    label="Você possui estepe?"
                    options={hasSteppeOptions}
                    style={{marginTop: 6}}
                    value={solicitation.tiresConfig.has_steppe}
                    error={errorHasSteppe}
                    helperText="A resposta não pode ser vazia."
                    onChange={(event) => dispatch(changeTiresConfig({
                        column: "has_steppe", 
                        value: event.target.value
                    }))}
                />
                {solicitation.tiresConfig.has_steppe === "sim" &&
                    <InputSelect 
                        label="O estepe está cheio?"
                        options={hasSteppeOptions}
                        style={{marginTop: 12}}
                        value={solicitation.tiresConfig.steppe_is_full}
                        error={errorSteppeIsFull}
                        helperText="A resposta não pode ser vazia."
                        onChange={(event) => dispatch(changeTiresConfig({
                            column: "steppe_is_full", 
                            value: event.target.value
                        }))}
                    />
                }
            </div>
        </>
    )
}

export default FormsPneus