import { useSelector, useDispatch } from "react-redux"
import PaymentsCheckout from "./Checkout"
import PaymentsSuccess from "./Success"
import Layout from "../../components/Layout"
import PixPayment from "./Pix"
import { setWebSocketReady } from "../../actions/webScocket"

const Payments = () => {

    const dispatch = useDispatch()

    const contextControler = useSelector(state => state.context)
    const webSocketConnection = useSelector(state => state.webSocket.connection)
    if (webSocketConnection.readyState === 1) {
        webSocketConnection.close()
        dispatch(setWebSocketReady(false))
    }

    if (contextControler.step === "pix") return <Layout><PixPayment /></Layout>
    else if (contextControler.step === "checkout") return <Layout><PaymentsCheckout /></Layout>
    return <PaymentsSuccess />
}

export default Payments