import { createSlice } from '@reduxjs/toolkit'

export const leadSlice = createSlice({
    name: "lead",
    initialState: {
        uuid: "",
        name: ""
    },
    reducers: {
        setUuidLead: (state, action) => {
            state.uuid = action.payload
        },
        setLeadName: (state, action) => {
            state.name = action.payload
        },
        setLastServiceSelected: (state, action) => {
            state.lastServiceSelected = action.payload
        },
        loadLead: (state, action) => {
            const { payload } = action
            
            state.name = payload.name === "Não informado" ? "" : payload.name
            state.uuid = payload.uuidLead
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    loadLead,
    setUuidLead,
    setLeadName,
    setLastServiceSelected
} = leadSlice.actions

export default leadSlice.reducer