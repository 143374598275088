import { useDispatch, useSelector } from 'react-redux'
import { changeSolicitation } from '../../../actions/solicitations'

import Title from '../../../components/Title';
import PaperServices from "../../../components/Paper/Service";
import { changeContext } from '../../../actions/context';
import HistServices from '../../../components/Paper/HistServices';

import './styles.css'
import { getSolicitation, getSolicitationsByUser } from '../../../services/solicitations';
import { useEffect, useState } from 'react';
import { setUuidLead } from '../../../actions/lead';
import api from '../../../services/api';
import ContactUsVector from '../../../assets/images/vector/contact-us.svg';
import Button from '../../../components/Button';

const Services = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    
    const action = urlParams.get('action')
    const service = urlParams.get('service')
    const uuidSolicitation = urlParams.get('solicitacao')

    const dispatch = useDispatch()

    const user = useSelector(state => state.user)
    const lead = useSelector(state => state.lead)
    const services = useSelector(state => state.services.auto)
    const loadingServices = useSelector(state => state.services.loading)

    const [servicesInProgress, setServicesInProgress] = useState([])

    const servicesId = {
        'Mecânico': "mecanico",
        'Socorro Bateria': "bateria",
        'Chaveiro': "chaveiro",
        'Combustível': "combustivel",
        'Troca de Pneus': "pneu",
        'Reboque': 'reboque'
    }

    const handleServiceSelect = (service) => {

        dispatch(changeSolicitation({
            column: "service",
            value: servicesId[service]
        }))

        dispatch(changeContext({
            active: "solicitation",
            step: "forms",
            action: "step1"
        }))
    }

    const getSolicitations = async () => {
        const uuid = user.type === "lead" ? lead.uuid : user.data.uuid
        if (uuid) {
            const solicitations = await getSolicitationsByUser(uuid, user.type)
            const solicitationsActive = solicitations ? solicitations.filter(item => ['active', 'new'].includes(item.status)) : []
    
            if (solicitationsActive.length > 0) {
                setServicesInProgress(solicitationsActive)
            }
        } else if (action === "restartSolicitation") {
            try {
                const response = await api.post('/solicitation/lookingForProvider', {
                    uuidSolicitation: uuidSolicitation
                })
    
                if (response.status === 200) window.location.href = '/'
            } catch (error) {
                console.log(error)
            }
        } else if (uuidSolicitation) {
            const solicitation = await getSolicitation(uuidSolicitation)
            const solicitationsActive = [solicitation].filter(item => ['active', 'new'].includes(item.status))

            if (solicitationsActive.length > 0) {
                setServicesInProgress(solicitationsActive)
                dispatch(setUuidLead(solicitation.uuidLead))
            }
        }
    }

    const redirectToChat = () => {
        window.location.href = `https://wa.me/5531992196001?text=Gostaria de um orçamento para uma nova bateria.`
    }

    useEffect(() => {
        getSolicitations()
    }, [user, lead])

    useEffect(() => {
        if (service) {
            dispatch(changeSolicitation({
                column: "service",
                value: service
            }))
    
            dispatch(changeContext({
                active: "solicitation",
                step: "forms",
                action: "step1"
            }))
        }
    }, [service])

    return (
        <div>
            {servicesInProgress.length > 0 &&
                <div className='services-hist-list-content'>
                    <Title label="Serviços em andamento"/>
                    <div className='services-hist-list'>
                        {servicesInProgress.map((item, index) => {
                            return (
                                <HistServices 
                                    key={index} 
                                    solicitation={item}
                                />
                            )
                        })}
                    </div>
                </div>
            }
            <div>
                <Title 
                    label="Selecione um serviço abaixo"
                    subtitle="Encontre agora o profissional ideal para te ajudar!"
                />
                <div className="services-grid">
                    {loadingServices ? (
                        Object.keys(servicesId).map(item => (
                            <PaperServices skeleton key={item}/>
                        ))
                    ) : (
                        services && services.map((item, index) => {
                            const serviceName = item.name === "Carga de Bateria" ? "Socorro Bateria" : item.name
                            return (
                                <PaperServices 
                                    key={index}
                                    icon={serviceName} 
                                    label={serviceName}
                                    onClick={() => handleServiceSelect(serviceName)}
                                />
                            )
                        })
                    )}
                </div>
            </div>
            <div style={{marginTop: 32}}>
                <Title 
                    label="Precisando de uma nova bateria?"
                    subtitle="Encontre a bateria ideal para o seu veículo e receba onde você estiver!"
                />
                <div className='contact-us-container'>
                    <img alt='' src={ContactUsVector}/>
                    <div className='contact-us-content'>
                        <span>Solicite um orçamento agora mesmo em nosso WhatsApp!</span>
                        <Button
                            label="Solicitar orçamento"
                            onClick={redirectToChat}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;